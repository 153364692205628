import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { IconOption } from './icons.constant';
import { CoreModule } from '@smartops-monorepo/client-common';
import { IconComponent } from '../icon';
import { NzToolTipComponent, NzTooltipDirective } from 'ng-zorro-antd/tooltip';

@Component({
  selector: 'spa-ui-icon-picker-content',
  standalone: true,
  imports: [CoreModule, IconComponent, NzToolTipComponent, NzTooltipDirective],
  templateUrl: './icon-picker-content.component.html',
  styleUrls: ['./icon-picker-content.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconPickerContentComponent {
  @Input() options: IconOption[] = [];
  @Input() value!: string;
  @Output() selection = new EventEmitter<IconOption>();

  trackByOption(index: number, option: IconOption) {
    return option.icon || index;
  }
}
