import { Pipe, PipeTransform } from '@angular/core';
import isString from 'lodash/isString';
import { TextManager } from '@smartops-monorepo/core-common';

@Pipe({
  name: 'capitalize',
  standalone: true,
})
export class CapitalizePipe implements PipeTransform {

  /**
   * Transforms the text to capitalize the first letter.
   *
   * @param {string} text - The text to transform.
   * @returns {string} - The transformed text.
   */
  public transform(text: string): string {
    if (!isString(text)) {
      return text;
    }
    return TextManager.capitalizeFirstLetter(text);
  }
}
