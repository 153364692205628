import { NgModule } from '@angular/core';
import { CoreModule } from './core.module';
import { RouterTestingModule } from '@angular/router/testing';
import { HttpClientTestingModule } from '@angular/common/http/testing';
import { CLIENT_CONFIG, ENVIRONMENT_CONFIG } from './config';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';

export const API_ENDPOINT = 'http://localhost:3000';
export const API_PREFIX = '/api';

@NgModule({
  imports: [
    CoreModule.forRoot(),
  ],
  providers: [
    { provide: CLIENT_CONFIG, useValue: { API_ENDPOINT, API_PREFIX } },
    { provide: ENVIRONMENT_CONFIG, useValue: { production: false } },
  ],
  exports: [
    NoopAnimationsModule,
    RouterTestingModule,
    HttpClientTestingModule,
  ],
})
export class ClientTestingModule {}
