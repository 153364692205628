import { Injectable, TemplateRef } from '@angular/core';
import { TextManager } from '@smartops-monorepo/core-common';

@Injectable({ providedIn: 'root' })
export class NotificationService {
  public displayNotification(
    type: 'success' | 'error' | 'warning' | 'info',
    message: string | TemplateRef<void>,
    title?: string | TemplateRef<void>,
  ): void {
    console.log('[NotificationService] displaySuccessNotification', { type, message, title });
  }

  /**
   * Used to display a notification when an action was successful.
   *
   * @public
   */
  public displayActionWasSuccessfulNotification() {
    const textCode: string = 'label:actionWasSuccessful';
    const message: string = TextManager.getTextFromCode(textCode, true);
    this.displayNotification('success', message);
  }

  /**
   * Used to display a notification when something went wrong.
   *
   * @public
   */
  public displaySomethingWentWrongNotification() {
    const textCode: string = 'warning:somethingWentWrong';
    const message: string = TextManager.getTextFromCode(textCode, true);
    this.displayNotification('error', message);
  }
}
