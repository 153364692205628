import { NgModule } from '@angular/core';
import { CoreModule as ClientCommonCoreModule } from '@smartops-monorepo/client-common';
import { AntDesignModules } from './ant-design';

@NgModule({
  imports: [ClientCommonCoreModule, AntDesignModules],
  exports: [ClientCommonCoreModule, AntDesignModules],
})
export class CoreModule {

}
