import { computed, inject, Injectable, Signal, signal, WritableSignal } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, Observable, of, ReplaySubject } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import get from 'lodash/get';
import { findIndex } from 'lodash';

function  log(...args: any[]) {
//  console.log( ...args)
}

@Injectable({
  providedIn: 'root',
})
export abstract class AbstractDataService<Entity> {
  protected dataCache: WritableSignal<Entity[]> = signal([] as Entity[]); // Cache to store data entities
  public readonly abstract url: string; // Your REST API endpoint
  protected entities$!: Observable<Entity[]>;
  private loadingSubject = new ReplaySubject<boolean>(1);
  protected readonly http: HttpClient = inject(HttpClient);
  public data: Signal<Entity[]> = computed(() => this.dataCache());
  private initialized: WritableSignal<boolean> = signal(false);
  private notLoaded: WritableSignal<boolean> = signal(true);

  hasData: Signal<boolean> = computed(() => {
    return this.initialized();
  });

  /**
   * Initializes the service by fetching data from the API if it not yet loaded.
   */
  public init(): void {
    if (!this.initialized() && this.notLoaded()) {
      this.notLoaded.set(false);
      this.loadData().subscribe({
        next: () => log('[Done Loading]')
      })
    }
  }

  // Fetch all entities
  public getEntities(): Observable<Entity[]> {
    if (!this.initialized() && this.notLoaded()) {
      this.notLoaded.set(false);
      return this.loadData();
    } else {
      return of(this.dataCache()); // Return cached data if available
    }
  }

  private loadData(): Observable<Entity[]> {
    return this.http.get<Entity[]>(this.url).pipe(
      tap((entities: Entity[]) => {
        this.dataCache.set(entities);
        this.initialized.set(true);
      }), // Store fetched data in cache
      catchError(this.handleError<Entity[]>('getEntities', []))
    );
  }

//  getEntities(): Observable<Entity[]> {
//    if (!this.entities$) {
//      this.entities$ = this.loadingSubject.asObservable().pipe(
//        switchMap(loading => {
//          if (loading) {
//            return of(null); // If a request is in progress, wait without doing anything
//          } else {
//            // Trigger the loading state
//            this.loadingSubject.next(true);
//
//            // If the cache is populated, return it, otherwise fetch from the API
//            return !this.hasData() ? of(this.dataCache()) : this.http.get<Entity[]>(this.url).pipe(
//              tap((data: any) => {
//                this.dataCache.set(data); // Populate the cache
//                this.loadingSubject.next(false); // Reset the loading state after data is loaded
//              }),
//              catchError(this.handleError<Entity[]>('getEntities', []))
//            );
//          }
//        }), // Ensure that the result of the first HTTP call is
//        shareReplay(1) // replayed to the subsequent subscribers
//      );
//    }
//
//    return this.entities$;
//  }

  // Fetch entity by ID
  public getEntityById(entityId: string): Observable<Entity> {
    return this.getEntities().pipe(map((entities: Entity[]) => {
      const index: number = this.getEntityIndex(entityId, entities);
      return entities[index];
    }));
  }

  // Create a new entity
  public addEntity<T>(entity: T): Observable<Entity> {
    return this.http.post<Entity>(this.url, entity).pipe(
      tap((newEntity: Entity) => {
        this.dataCache.update((entities: Entity[]) => [...entities, newEntity]);
      }), // Add new data to cache
      catchError(this.handleError<Entity>('addEntity'))
    );
  }

  // Update an existing entity
  public updateEntity<T>(entityId: string, entity: T): Observable<any> {
    return this.http.put<Entity>(`${this.url}/${entityId}`, entity).pipe(
      tap((result: Entity) => {
        this.dataCache.update((entities: Entity[]) => {
          const index: number = this.getEntityIndex(entityId, entities);
          entities[index] = result;
          return [...entities];
        });
      }),
      catchError(this.handleError<any>('updateEntity'))
    );
  }

  /**
   * Performs a delete entity call and then deletes that entity from the cache as well.
   * @param {string} entityId - The entity id to delete.
   * @return {Observable<void>} - An observable that resolves when the entity is deleted.
   * @public
   */
  public deleteEntity(entityId: string): Observable<void> {
    return this.http.delete<void>(`${this.url}/${entityId}`).pipe(
      tap(() => {
        this.dataCache.update((entities: Entity[]) => {
          const index: number = this.getEntityIndex(entityId, entities);
          entities.splice(index, 1);
          return [...entities];
        });
      }),
      catchError(this.handleError<void>('deleteEntity'))
    );
  }

  private getEntityIndex(entityId: string, entities: Entity[]): number {
    return entities.findIndex((entity: Entity) => get(entity, '_id') === entityId);
  }

  // Handle HTTP operation that failed and let the app continue
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      // TODO: send the error to your remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for Entity consumption
      console.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result
      return of(result as T);
    };
  }

  /**
   * Upserts an entity in the cache.
   * @template Entity
   * @param {Entity} entity  - The entity to upsert.
   * @param {Record<string, any>} findQuery - The query to check if the entity already exists.
   * @protected
   */
  protected upsert(entity: Entity, findQuery: Record<string, any>) {
    const currentIndex: number = findIndex(this.data(), findQuery);
    const isAddingNewEntity: boolean = currentIndex === -1;
    this.dataCache.update((entities) => {
      if (isAddingNewEntity) entities.push(entity);
      else entities[currentIndex] = entity;
      return [...entities];
    });
  }
}
