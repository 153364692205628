import { NotificationService } from '@smartops-monorepo/client-common/service';
import { inject, Injectable, TemplateRef } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { TextManager } from '@smartops-monorepo/core-common';

@Injectable({ providedIn: 'root' })
export class AntNotificationService extends NotificationService {

  private notification: NzNotificationService = inject(NzNotificationService);

  public override displayNotification(
    type: 'success' | 'error' | 'warning' | 'info',
    message: string | TemplateRef<void>,
    title?: string | TemplateRef<void>,
  ): void {
    const notificationTitle = title ?? TextManager
      .getTextFromCode(`label:${type}Notification`);
    this.notification.create(type, notificationTitle, message)
  }
}
