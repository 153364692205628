import { BaseControl, DataRecord } from '../base';
import { TemplateRef } from '@angular/core';

export interface SelectOption {
  label: string;
  value?: string;
  options?: SelectOption[];
}

export interface SelectOptionGroup {
  label: string;
  options: SelectOption[];
}

export type SelectType = 'default' | 'dynamic';
export const DEFAULT_SELECT: SelectType = 'default';
export const DYNAMIC_SELECT: SelectType = 'dynamic';

export interface SelectConfig {
  type: SelectType;
  clearable?: boolean;
  searchable?: boolean;
  options: SelectOption[];
  customTemplate?: TemplateRef<any>;
}

export type GetOptionsFn = (data: DataRecord, control?: DynamicSelectControl) => any[];
export type GetOptionsAsyncFn = (data: DataRecord, control?: DynamicSelectControl) => Promise<any[]>;

export interface DynamicSelectConfig extends SelectConfig {
  getOptions: GetOptionsFn;
  getOptionsAsync?: GetOptionsAsyncFn;
//  getValue:
  async: boolean;
  pathToLabel?: string;
  pathToValue?: string;
  initialValue?: string;
}

export interface DynamicSelectControl extends BaseControl {
  config: DynamicSelectConfig;
}

export interface SelectControl extends BaseControl {
  config: SelectConfig;
}
