@if(hasLayout()) {
  <spa-ui-input-wrapper
    [layout]="layout()"
    [labelText]="control().label | text"
    [type]="labelType()"
  >
    <ng-container [ngTemplateOutlet]="inputGroup">
    </ng-container>
  </spa-ui-input-wrapper>
} @else {
  <ng-container [ngTemplateOutlet]="inputGroup">
  </ng-container>
}

<ng-template #inputGroup>
  <spa-ui-inline-input-icon-wrapper
    [prefixIcon]="control() | get:'prefixIcon'"
    [suffixIcon]="control() | get:'suffixIcon'"
  >
    <ng-container [ngTemplateOutlet]="inputs"></ng-container>
  </spa-ui-inline-input-icon-wrapper>
</ng-template>


<ng-template #inputs>
  <ng-container>
    @switch (type()) {
      @case ('checkbox') {
        <spa-ui-checkbox
          [ngModel]="value()"
          (ngModelChange)="onInputChange($event)"
          [disabled]="disabled()"
        >
        </spa-ui-checkbox>
      }
      @case ('date') {
        <spa-ui-date-picker
          [ngModel]="value()"
          (ngModelChange)="onInputChange($event)"
          (onFocus)="onFocus.emit()"
          [placeholder]="placeholder()"
          [initialValue]="config() | get:'initialValue'"
          [min]="config() | get:'min'"
          [max]="config() | get:'max'"
          [format]="config() | get:'format'"
          [range]="config() | get:'range'"
          [tabindex]="tabIndex()"
          [hasFocus]="hasFocus()"
          (keyDown)="keyDown.emit($event)"
          [disabled]="disabled()"
          [readonly]="readonly()"
        >
        </spa-ui-date-picker>
      }
      @case ('time') {
        <spa-ui-time-picker
          [ngModel]="value()"
          (ngModelChange)="onInputChange($event)"
          (onFocus)="onFocus.emit()"
          [placeholder]="placeholder()"
          [hasFocus]="hasFocus()"
          (keyDown)="keyDown.emit($event)"
          [initialValue]="config() | get:'initialValue'"
          [min]="config() | get:'min'"
          [max]="config() | get:'max'"
          [format]="config() | get:'format'"
          [hourStep]="config() | get:'hourStep'"
          [minuteStep]="config() | get:'minuteStep'"
          [secondStep]="config() | get:'secondStep'"
          [disabled]="disabled()"
          [readonly]="readonly()"
        >
        </spa-ui-time-picker>
      }
      @case ('number') {
        <spa-ui-number
          [ngModel]="value()"
          (ngModelChange)="onInputChange($event)"
          (onFocus)="onFocus.emit()"
          [placeholder]="placeholder()"
          [hasFocus]="hasFocus()"
          (keyDown)="keyDown.emit($event)"
          [min]="config() | get:'min'"
          [max]="config() | get:'max'"
          [step]="config() | get:'step'"
          [disabled]="disabled()"
          [readonly]="readonly()"
        >
        </spa-ui-number>
      }
      @case ('textarea') {
        <spa-ui-textarea
          [ngModel]="value()"
          (ngModelChange)="onInputChange($event)"
          (onFocus)="onFocus.emit()"
          [placeholder]="placeholder()"
          [hasFocus]="hasFocus()"
          (keyDown)="keyDown.emit($event)"
          [disabled]="disabled()"
          [readonly]="readonly()"
          [minRows]="config() | get:'minRows'"
          [maxRows]="config() | get:'maxRows'"
        >
        </spa-ui-textarea>
      }
      @case ('select') {
        <spa-ui-select
          [ngModel]="value()"
          (ngModelChange)="onInputChange($event)"
          (onFocus)="onFocus.emit()"
          [placeholder]="placeholder()"
          [hasFocus]="hasFocus()"
          (keyDown)="keyDown.emit($event)"
          [disabled]="disabled()"
          [readonly]="readonly()"
          [control]="control()"
          [data]="data()"
          [clearable]="config() | get:'clearable'"
          [searchable]="config() | get:'searchable'"
        >
        </spa-ui-select>
      }
      @case ('color-picker') {
        <spa-ui-color-picker
          [ngModel]="value()"
          (ngModelChange)="onInputChange($event)"
          (onFocus)="onFocus.emit()"
          [placeholder]="placeholder()"
          [hasFocus]="hasFocus()"
          (keyDown)="keyDown.emit($event)"
          [disabled]="disabled()"
          [readonly]="readonly()"
        ></spa-ui-color-picker>
      }
      @case ('icon-picker') {
        <spa-ui-icon-picker
          [ngModel]="value()"
          (ngModelChange)="onInputChange($event)"
          (onFocus)="onFocus.emit()"
          [placeholder]="placeholder()"
          [hasFocus]="hasFocus()"
          (keyDown)="keyDown.emit($event)"
          [disabled]="disabled()"
          [readonly]="readonly()"
        ></spa-ui-icon-picker>
      }
      @default {
        <input
          nz-input
          [ngModel]="value()"
          (ngModelChange)="onInputChange($event)"
          (click)="onFocus.emit()"
          [type]="type()"
          [placeholder]="placeholder()"
          (keydown)="keyDown.emit($event)"
          [tabindex]="tabIndex()"
          spa-ui-focus
          [hasFocus]="hasFocus()"
          [disabled]="disabled()"
          [readonly]="readonly()"
        />
      }
    }
  </ng-container>
</ng-template>
