import { IsDateString, IsDefined, IsEmail, IsEnum, IsMongoId, IsOptional, IsString, IsStrongPassword } from 'class-validator';
import { Exclude, Expose } from 'class-transformer';
import type { UserRole } from '@smartops-monorepo/types/user';
import { Admin, Recruiter, ShiftPlanner, SuperAdmin, UserRoles, Worker } from '@smartops-monorepo/types/user';

export class UserDTO {
  @Expose()
  @IsDefined()
  @IsMongoId()
  _id!: string;

  @Expose()
  @IsEmail()
  @IsDefined()
  email!: string;

  @Exclude()
  @IsStrongPassword()
  password!: string;

  @Expose()
  @IsDefined()
  @IsString()
  firstName!: string;

  @Expose()
  @IsDefined()
  @IsString()
  lastName!: string;

  @Expose()
  @IsOptional()
  @IsString()
  avatar!: string;

  @Expose({ groups: [Admin, Recruiter, SuperAdmin, ShiftPlanner, Worker] })
  @IsEnum(UserRoles, { each: true })
  roles!: UserRole[];


  @Expose({ groups: [Admin, Recruiter, SuperAdmin, ShiftPlanner] })
  @IsOptional()
  @IsDateString()
  createdAt!: string;

  @Expose({ groups: [Admin, Recruiter, SuperAdmin, ShiftPlanner] })
  @IsOptional()
  @IsDateString()
  updatedAt!: string;
}
