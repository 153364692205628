import { Expose, Type } from 'class-transformer';
import { ValidateNested } from 'class-validator';
import { ShiftAvailabilityDTO } from './shift-availability.dto';
import { WorkerDTO } from './worker.dto';

export class ShiftWorkersDTO {
  constructor(availabilities: ShiftAvailabilityDTO[], workers: WorkerDTO[]) {
    this.availabilities = availabilities;
    this.workers = workers;
  }

  @Expose()
  @ValidateNested({ each: true })
  @Type(() => ShiftAvailabilityDTO)
  availabilities!: ShiftAvailabilityDTO[];

  @Expose()
  @ValidateNested({ each: true })
  @Type(() => WorkerDTO)
  workers!: WorkerDTO[];
}
