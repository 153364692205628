export type PlanningRole = 'employer' | 'shift-planner' | 'worker';
type RecruitmentRole = 'candidate' | 'recruiter' | 'recruitment-client';
type CompanyRole = 'employee' | 'manager';
type AdminRole = 'admin' | 'super-admin';

export const Employer: PlanningRole = 'employer';
export const ShiftPlanner: PlanningRole = 'shift-planner';
export const Worker: PlanningRole = 'worker';

export const Candidate: RecruitmentRole = 'candidate';
export const Recruiter: RecruitmentRole = 'recruiter';
export const RecruitmentClient: RecruitmentRole = 'recruitment-client';

export const Employee: CompanyRole = 'employee';
export const Manager: CompanyRole = 'manager';

export const Admin: AdminRole = 'admin';
export const SuperAdmin: AdminRole = 'super-admin';

/**
 *
 */
export type UserRole = AdminRole | CompanyRole | RecruitmentRole | PlanningRole;

export type UserRolesType = { Admin: AdminRole, Candidate: RecruitmentRole, Employee: CompanyRole, Employer: PlanningRole, Manager: CompanyRole, Recruiter: RecruitmentRole, RecruitmentClient: RecruitmentRole, ShiftPlanner: PlanningRole, SuperAdmin: AdminRole, Worker: PlanningRole };

/**
 *
 * @type {UserRolesType}
 */
export const USER_ROLES: UserRolesType = {
  Admin,
  Candidate,
  Employee,
  Employer,
  Manager,
  Recruiter,
  RecruitmentClient,
  ShiftPlanner,
  SuperAdmin,
  Worker,
}

export type IUserRoles = { [key: string]: UserRole };

export const UserRoles: IUserRoles = {
  ShiftPlanner,
  Worker,
  Candidate,
  Recruiter,
  RecruitmentClient,
  Employee,
  Manager,
  Admin,
  SuperAdmin,
}

/**
 *
 * @type {Readonly<UserRole[]>}
 */
export const UserRolesEnum: Readonly<UserRole[]> = Object
  .freeze(Object.values(USER_ROLES));


export interface IUser {
  _id?: string;
  email: string;
  password?: string;
  firstName: string;
  lastName: string;
  roles: UserRole[];
  avatar?: string;
  createdAt?: Date;
  updatedAt?: Date;
}
