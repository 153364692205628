import { Inject, Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { catchError, Observable, switchMap, throwError } from 'rxjs';
import { AuthService } from '../service';
import { CLIENT_CONFIG, ClientConfig } from '../config';
import { fromPromise } from 'rxjs/internal/observable/innerFrom';
import noop from 'lodash/noop';

@Injectable({
  providedIn: 'root',
})
export class JwtTokenInterceptor implements HttpInterceptor {
  /**
   * Constructor of the interceptor class.
   *
   * @param {ClientConfig} config - The client config values.
   * @param {AuthService} authService - The authentication service.
   */
  constructor(@Inject(CLIENT_CONFIG) private config: ClientConfig, private authService: AuthService) {}

  /**
   * Intercepts the request and adds the JWT Token to the request.
   *
   * @param {HttpRequest<any>} request
   * @param {HttpHandler} next
   * @returns {Observable<HttpEvent<any>>}
   */
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const { API_ENDPOINT, API_PREFIX } = this.config;
    const isRefreshTokenRequest = request.url.startsWith(`${API_ENDPOINT}${API_PREFIX}/auth/refresh`);
    return next.handle(request).pipe(catchError((error: HttpErrorResponse) => {
      if (error?.status === 401 && !isRefreshTokenRequest) {
        return this.refreshTokenMethod(request, next);
      }
      return throwError(() => error);
    }));
  }

  /**
   * Refreshes the JWT Token.
   *
   * @returns {Observable<HttpEvent<any>>} The request that gets refresh token.
   * @private
   */
  private refreshTokenMethod(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return fromPromise(this.authService.refreshAccessToken()).pipe(
      switchMap(() => next.handle(request)),
      catchError((error: HttpErrorResponse) => {
        if (error?.status === 403) {
          this.authService.logoutActions().then(noop);
        }
        return throwError(() => error);
      }),
    );
  }
}
