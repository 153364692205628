import { IUser } from '../user';
import { IShift } from './Shift';

export type ShiftVerificationStatus = 'accepted' | 'rejected';
export const ShiftVerificationStatuses: ShiftVerificationStatus[] = ['accepted', 'rejected'];
export type ShiftActionType = 'accept' | 'assign' | 'decline' | 'reject' | 'remove';

export const SCHEDULED_TYPES: ShiftActionType[] = ['accept', 'assign'];
export const NOT_SCHEDULED_TYPES: ShiftActionType[] = ['decline', 'reject'];
export const ShiftActionTypes: ShiftActionType[] = ['accept', 'assign', 'decline', 'reject', 'remove'];

export interface IShiftWorkerAction {
  actionType: ShiftActionType,
  timestamp?: string | Date,
  actionBy: string | IUser,
}

export interface IShiftVerification {
  status: ShiftVerificationStatus,
  verifiedBy: string | IUser,
  timestamp?: string | Date,
}

export interface IShiftWorkerTimeAdjustment {
  startTime: string | Date;
  endTime: string | Date;
  breakDuration: number;
  timestamp: string | Date;
  actionBy: string | IUser;
}

export interface IShiftWorkerComment {
  comment: string;
  timestamp: string | Date;
  commentBy: string;
}

export interface IShiftWorkerRecord {
  _id?: string;
  shift: string | IShift;
  worker: string | IUser;

  status?: ShiftActionType;
  verificationStatus?: ShiftVerificationStatus;

  startTime?: Date;
  endTime?: Date;
  breakDuration?: number;

  actions?: IShiftWorkerAction[];
  timeAdjustments?: IShiftWorkerTimeAdjustment[];
  verifications?: IShiftVerification[];

  createdAt?: Date;
  updatedAt?: Date;
}
