import { inject, Injectable, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';


@Injectable({ providedIn: 'platform' })
export class PlatformService {

  public readonly platformId: object = inject(PLATFORM_ID);

  public isRenderedOnServer(): boolean {
    return !isPlatformBrowser(this.platformId);
  }

  public isRenderedOnBrowser(): boolean {
    return isPlatformBrowser(this.platformId);
  }
}
