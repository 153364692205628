@for (option of options; track trackByOption($index, option); let last = $last) {
  <div
    class="icon-picker-content-item"
    [class.is-selected]="option.icon === value"
    (click)="selection.emit(option)"
    nz-tooltip
    [nzTooltipTitle]="option.text"
    nzTooltipPlacement="top"
    nzTooltipTrigger="hover"
    [class.is-last-icon-option]="last"
    data-testid="icon-option"
    title="{{option.text}}"
    role="option"
    >
    <spa-ui-icon [icon]="option.icon"></spa-ui-icon>
  </div>
}
