export type IconOption = { text: string, icon: string };

export const AntDesignIcons: IconOption[] = [
  { icon: 'step-backward', text: 'Step Backward' },
  { icon: 'step-forward', text: 'Step Forward' },
  { icon: 'fast-backward', text: 'Fast Backward' },
  { icon: 'fast-forward', text: 'Fast Forward' },
  { icon: 'shrink', text: 'Shrink' },
  { icon: 'arrows-alt', text: 'Arrows Alt' },
  { icon: 'down', text: 'Down' },
  { icon: 'up', text: 'Up' },
  { icon: 'left', text: 'Left' },
  { icon: 'right', text: 'Right' },
  { icon: 'caret-up', text: 'Caret Up' },
  { icon: 'caret-down', text: 'Caret Down' },
  { icon: 'caret-left', text: 'Caret Left' },
  { icon: 'caret-right', text: 'Caret Right' },
  { icon: 'up-circle', text: 'Up Circle' },
  { icon: 'down-circle', text: 'Down Circle' },
  { icon: 'left-circle', text: 'Left Circle' },
  { icon: 'right-circle', text: 'Right Circle' },
  { icon: 'double-right', text: 'Double Right' },
  { icon: 'double-left', text: 'Double Left' },
  { icon: 'vertical-left', text: 'Vertical Left' },
  { icon: 'vertical-right', text: 'Vertical Right' },
  { icon: 'vertical-align-top', text: 'Vertical Align Top' },
  { icon: 'vertical-align-middle', text: 'Vertical Align Middle' },
  { icon: 'vertical-align-bottom', text: 'Vertical Align Bottom' },
  { icon: 'forward', text: 'Forward' },
  { icon: 'backward', text: 'Backward' },
  { icon: 'rollback', text: 'Rollback' },
  { icon: 'enter', text: 'Enter' },
  { icon: 'retweet', text: 'Retweet' },
  { icon: 'swap', text: 'Swap' },
  { icon: 'swap-left', text: 'Swap Left' },
  { icon: 'swap-right', text: 'Swap Right' },
  { icon: 'arrow-up', text: 'Arrow Up' },
  { icon: 'arrow-down', text: 'Arrow Down' },
  { icon: 'arrow-left', text: 'Arrow Left' },
  { icon: 'arrow-right', text: 'Arrow Right' },
  { icon: 'play-circle', text: 'Play Circle' },
  { icon: 'up-square', text: 'Up Square' },
  { icon: 'down-square', text: 'Down Square' },
  { icon: 'left-square', text: 'Left Square' },
  { icon: 'right-square', text: 'Right Square' },
  { icon: 'login', text: 'Login' },
  { icon: 'logout', text: 'Logout' },
  { icon: 'menu-fold', text: 'Menu Fold' },
  { icon: 'menu-unfold', text: 'Menu Unfold' },
  { icon: 'border-bottom', text: 'Border Bottom' },
  { icon: 'border-horizontal', text: 'Border Horizontal' },
  { icon: 'border-inner', text: 'Border Inner' },
  { icon: 'border-outer', text: 'Border Outer' },
  { icon: 'border-left', text: 'Border Left' },
  { icon: 'border-right', text: 'Border Right' },
  { icon: 'border-top', text: 'Border Top' },
  { icon: 'border-verticle', text: 'Border Verticle' },
  { icon: 'pic-center', text: 'Pic Center' },
  { icon: 'pic-left', text: 'Pic Left' },
  { icon: 'pic-right', text: 'Pic Right' },
  { icon: 'radius-bottomleft', text: 'Radius Bottomleft' },
  { icon: 'radius-bottomright', text: 'Radius Bottomright' },
  { icon: 'radius-upleft', text: 'Radius Upleft' },
  { icon: 'radius-upright', text: 'Radius Upright' },
  { icon: 'fullscreen', text: 'Fullscreen' },
  { icon: 'fullscreen-exit', text: 'Fullscreen Exit' },
  { icon: 'question', text: 'Question' },
  { icon: 'question-circle', text: 'Question Circle' },
  { icon: 'plus', text: 'Plus' },
  { icon: 'plus-circle', text: 'Plus Circle' },
  { icon: 'pause', text: 'Pause' },
  { icon: 'pause-circle', text: 'Pause Circle' },
  { icon: 'minus', text: 'Minus' },
  { icon: 'minus-circle', text: 'Minus Circle' },
  { icon: 'plus-square', text: 'Plus Square' },
  { icon: 'minus-square', text: 'Minus Square' },
  { icon: 'info', text: 'Info' },
  { icon: 'info-circle', text: 'Info Circle' },
  { icon: 'exclamation', text: 'Exclamation' },
  { icon: 'exclamation-circle', text: 'Exclamation Circle' },
  { icon: 'close', text: 'Close' },
  { icon: 'close-circle', text: 'Close Circle' },
  { icon: 'close-square', text: 'Close Square' },
  { icon: 'check', text: 'Check' },
  { icon: 'check-circle', text: 'Check Circle' },
  { icon: 'check-square', text: 'Check Square' },
  { icon: 'clock-circle', text: 'Clock Circle' },
  { icon: 'warning', text: 'Warning' },
  { icon: 'issues-close', text: 'Issues Close' },
  { icon: 'stop', text: 'Stop' },
  { icon: 'edit', text: 'Edit' },
  { icon: 'form', text: 'Form' },
  { icon: 'copy', text: 'Copy' },
  { icon: 'scissor', text: 'Scissor' },
  { icon: 'delete', text: 'Delete' },
  { icon: 'snippets', text: 'Snippets' },
  { icon: 'diff', text: 'Diff' },
  { icon: 'highlight', text: 'Highlight' },
  { icon: 'align-center', text: 'Align Center' },
  { icon: 'align-left', text: 'Align Left' },
  { icon: 'align-right', text: 'Align Right' },
  { icon: 'bg-colors', text: 'Bg Colors' },
  { icon: 'bold', text: 'Bold' },
  { icon: 'italic', text: 'Italic' },
  { icon: 'underline', text: 'Underline' },
  { icon: 'strikethrough', text: 'Strikethrough' },
  { icon: 'redo', text: 'Redo' },
  { icon: 'undo', text: 'Undo' },
  { icon: 'zoom-in', text: 'Zoom In' },
  { icon: 'zoom-out', text: 'Zoom Out' },
  { icon: 'font-colors', text: 'Font Colors' },
  { icon: 'font-size', text: 'Font Size' },
  { icon: 'line-height', text: 'Line Height' },
  { icon: 'dash', text: 'Dash' },
  { icon: 'small-dash', text: 'Small Dash' },
  { icon: 'sort-ascending', text: 'Sort Ascending' },
  { icon: 'sort-descending', text: 'Sort Descending' },
  { icon: 'drag', text: 'Drag' },
  { icon: 'ordered-list', text: 'Ordered List' },
  { icon: 'unordered-list', text: 'Unordered List' },
  { icon: 'radius-setting', text: 'Radius Setting' },
  { icon: 'column-width', text: 'Column Width' },
  { icon: 'column-height', text: 'Column Height' },
  { icon: 'area-chart', text: 'Area Chart' },
  { icon: 'pie-chart', text: 'Pie Chart' },
  { icon: 'bar-chart', text: 'Bar Chart' },
  { icon: 'dot-chart', text: 'Dot Chart' },
  { icon: 'line-chart', text: 'Line Chart' },
  { icon: 'radar-chart', text: 'Radar Chart' },
  { icon: 'heat-map', text: 'Heat Map' },
  { icon: 'fall', text: 'Fall' },
  { icon: 'rise', text: 'Rise' },
  { icon: 'stock', text: 'Stock' },
  { icon: 'box-plot', text: 'Box Plot' },
  { icon: 'fund', text: 'Fund' },
  { icon: 'sliders', text: 'Sliders' },
  { icon: 'android', text: 'Android' },
  { icon: 'apple', text: 'Apple' },
  { icon: 'windows', text: 'Windows' },
  { icon: 'ie', text: 'Ie' },
  { icon: 'chrome', text: 'Chrome' },
  { icon: 'github', text: 'Github' },
  { icon: 'aliwangwang', text: 'Aliwangwang' },
  { icon: 'dingding', text: 'Dingding' },
  { icon: 'weibo-square', text: 'Weibo Square' },
  { icon: 'weibo-circle', text: 'Weibo Circle' },
  { icon: 'taobao-circle', text: 'Taobao Circle' },
  { icon: 'html5', text: 'Html5' },
  { icon: 'weibo', text: 'Weibo' },
  { icon: 'twitter', text: 'Twitter' },
  { icon: 'wechat', text: 'Wechat' },
  { icon: 'youtube', text: 'Youtube' },
  { icon: 'alipay-circle', text: 'Alipay Circle' },
  { icon: 'taobao', text: 'Taobao' },
  { icon: 'skype', text: 'Skype' },
  { icon: 'qq', text: 'Qq' },
  { icon: 'medium-workmark', text: 'Medium Workmark' },
  { icon: 'gitlab', text: 'Gitlab' },
  { icon: 'medium', text: 'Medium' },
  { icon: 'linkedin', text: 'Linkedin' },
  { icon: 'google-plus', text: 'Google Plus' },
  { icon: 'dropbox', text: 'Dropbox' },
  { icon: 'facebook', text: 'Facebook' },
  { icon: 'codepen', text: 'Codepen' },
  { icon: 'code-sandbox', text: 'Code Sandbox' },
  { icon: 'amazon', text: 'Amazon' },
  { icon: 'google', text: 'Google' },
  { icon: 'codepen-circle', text: 'Codepen Circle' },
  { icon: 'alipay', text: 'Alipay' },
  { icon: 'ant-design', text: 'Ant Design' },
  { icon: 'ant-cloud', text: 'Ant Cloud' },
  { icon: 'aliyun', text: 'Aliyun' },
  { icon: 'zhihu', text: 'Zhihu' },
  { icon: 'slack', text: 'Slack' },
  { icon: 'slack-square', text: 'Slack Square' },
  { icon: 'behance', text: 'Behance' },
  { icon: 'behance-square', text: 'Behance Square' },
  { icon: 'dribbble', text: 'Dribbble' },
  { icon: 'dribbble-square', text: 'Dribbble Square' },
  { icon: 'instagram', text: 'Instagram' },
  { icon: 'yuque', text: 'Yuque' },
  { icon: 'alibaba', text: 'Alibaba' },
  { icon: 'yahoo', text: 'Yahoo' },
  { icon: 'reddit', text: 'Reddit' },
  { icon: 'sketch', text: 'Sketch' },
  { icon: 'account-book', text: 'Account Book' },
  { icon: 'alert', text: 'Alert' },
  { icon: 'aim', text: 'Aim' },
  { icon: 'api', text: 'Api' },
  { icon: 'appstore', text: 'Appstore' },
  { icon: 'appstore-add', text: 'Appstore Add' },
  { icon: 'audio-muted', text: 'Audio Muted' },
  { icon: 'audit', text: 'Audit' },
  { icon: 'audio', text: 'Audio' },
  { icon: 'bank', text: 'Bank' },
  { icon: 'bars', text: 'Bars' },
  { icon: 'bell', text: 'Bell' },
  { icon: 'apartment', text: 'Apartment' },
  { icon: 'block', text: 'Block' },
  { icon: 'barcode', text: 'Barcode' },
  { icon: 'borderless-table', text: 'Borderless Table' },
  { icon: 'border', text: 'Border' },
  { icon: 'branches', text: 'Branches' },
  { icon: 'bug', text: 'Bug' },
  { icon: 'build', text: 'Build' },
  { icon: 'bulb', text: 'Bulb' },
  { icon: 'calculator', text: 'Calculator' },
  { icon: 'car', text: 'Car' },
  { icon: 'camera', text: 'Camera' },
  { icon: 'calendar', text: 'Calendar' },
  { icon: 'book', text: 'Book' },
  { icon: 'carry-out', text: 'Carry Out' },
  { icon: 'clear', text: 'Clear' },
  { icon: 'ci-circle', text: 'Ci Circle' },
  { icon: 'cloud-download', text: 'Cloud Download' },
  { icon: 'cloud-server', text: 'Cloud Server' },
  { icon: 'ci', text: 'Ci' },
  { icon: 'cloud-sync', text: 'Cloud Sync' },
  { icon: 'cloud', text: 'Cloud' },
  { icon: 'cluster', text: 'Cluster' },
  { icon: 'cloud-upload', text: 'Cloud Upload' },
  { icon: 'coffee', text: 'Coffee' },
  { icon: 'code', text: 'Code' },
  { icon: 'compress', text: 'Compress' },
  { icon: 'contacts', text: 'Contacts' },
  { icon: 'compass', text: 'Compass' },
  { icon: 'comment', text: 'Comment' },
  { icon: 'container', text: 'Container' },
  { icon: 'control', text: 'Control' },
  { icon: 'database', text: 'Database' },
  { icon: 'crown', text: 'Crown' },
  { icon: 'delete-column', text: 'Delete Column' },
  { icon: 'dashboard', text: 'Dashboard' },
  { icon: 'deployment-unit', text: 'Deployment Unit' },
  { icon: 'desktop', text: 'Desktop' },
  { icon: 'delivered-procedure', text: 'Delivered Procedure' },
  { icon: 'dingtalk', text: 'Dingtalk' },
  { icon: 'delete-row', text: 'Delete Row' },
  { icon: 'credit-card', text: 'Credit Card' },
  { icon: 'dollar-circle', text: 'Dollar Circle' },
  { icon: 'dislike', text: 'Dislike' },
  { icon: 'dollar', text: 'Dollar' },
  { icon: 'copyright', text: 'Copyright' },
  { icon: 'download', text: 'Download' },
  { icon: 'ellipsis', text: 'Ellipsis' },
  { icon: 'environment', text: 'Environment' },
  { icon: 'euro-circle', text: 'Euro Circle' },
  { icon: 'expand-alt', text: 'Expand Alt' },
  { icon: 'expand', text: 'Expand' },
  { icon: 'customer-service', text: 'Customer Service' },
  { icon: 'exception', text: 'Exception' },
  { icon: 'euro', text: 'Euro' },
  { icon: 'eye-invisible', text: 'Eye Invisible' },
  { icon: 'export', text: 'Export' },
  { icon: 'field-number', text: 'Field Number' },
  { icon: 'field-string', text: 'Field String' },
  { icon: 'field-time', text: 'Field Time' },
  { icon: 'file-add', text: 'File Add' },
  { icon: 'file-done', text: 'File Done' },
  { icon: 'file-excel', text: 'File Excel' },
  { icon: 'file-exclamation', text: 'File Exclamation' },
  { icon: 'field-binary', text: 'Field Binary' },
  { icon: 'file-gif', text: 'File Gif' },
  { icon: 'file-jpg', text: 'File Jpg' },
  { icon: 'file-markdown', text: 'File Markdown' },
  { icon: 'file-image', text: 'File Image' },
  { icon: 'file-pdf', text: 'File Pdf' },
  { icon: 'file-search', text: 'File Search' },
  { icon: 'file-ppt', text: 'File Ppt' },
  { icon: 'file-protect', text: 'File Protect' },
  { icon: 'file-unknown', text: 'File Unknown' },
  { icon: 'file-text', text: 'File Text' },
  { icon: 'file-sync', text: 'File Sync' },
  { icon: 'filter', text: 'Filter' },
  { icon: 'file-word', text: 'File Word' },
  { icon: 'file-zip', text: 'File Zip' },
  { icon: 'flag', text: 'Flag' },
  { icon: 'fire', text: 'Fire' },
  { icon: 'folder-add', text: 'Folder Add' },
  { icon: 'folder', text: 'Folder' },
  { icon: 'fork', text: 'Fork' },
  { icon: 'folder-view', text: 'Folder View' },
  { icon: 'folder-open', text: 'Folder Open' },
  { icon: 'experiment', text: 'Experiment' },
  { icon: 'function', text: 'Function' },
  { icon: 'gateway', text: 'Gateway' },
  { icon: 'fund-view', text: 'Fund View' },
  { icon: 'format-painter', text: 'Format Painter' },
  { icon: 'gift', text: 'Gift' },
  { icon: 'gif', text: 'Gif' },
  { icon: 'fund-projection-screen', text: 'Fund Projection Screen' },
  { icon: 'funnel-plot', text: 'Funnel Plot' },
  { icon: 'global', text: 'Global' },
  { icon: 'frown', text: 'Frown' },
  { icon: 'gold', text: 'Gold' },
  { icon: 'hdd', text: 'Hdd' },
  { icon: 'heart', text: 'Heart' },
  { icon: 'group', text: 'Group' },
  { icon: 'holder', text: 'Holder' },
  { icon: 'home', text: 'Home' },
  { icon: 'idcard', text: 'Idcard' },
  { icon: 'hourglass', text: 'Hourglass' },
  { icon: 'copyright-circle', text: 'Copyright Circle' },
  { icon: 'insert-row-above', text: 'Insert Row Above' },
  { icon: 'inbox', text: 'Inbox' },
  { icon: 'insert-row-left', text: 'Insert Row Left' },
  { icon: 'import', text: 'Import' },
  { icon: 'key', text: 'Key' },
  { icon: 'insert-row-below', text: 'Insert Row Below' },
  { icon: 'insurance', text: 'Insurance' },
  { icon: 'interaction', text: 'Interaction' },
  { icon: 'insert-row-right', text: 'Insert Row Right' },
  { icon: 'laptop', text: 'Laptop' },
  { icon: 'file', text: 'File' },
  { icon: 'layout', text: 'Layout' },
  { icon: 'like', text: 'Like' },
  { icon: 'link', text: 'Link' },
  { icon: 'loading-3-quarters', text: 'Loading3 Quarters' },
  { icon: 'line', text: 'Line' },
  { icon: 'lock', text: 'Lock' },
  { icon: 'mail', text: 'Mail' },
  { icon: 'mac-command', text: 'Mac Command' },
  { icon: 'loading', text: 'Loading' },
  { icon: 'console-sql', text: 'Console Sql' },
  { icon: 'man', text: 'Man' },
  { icon: 'medicine-box', text: 'Medicine Box' },
  { icon: 'eye', text: 'Eye' },
  { icon: 'meh', text: 'Meh' },
  { icon: 'message', text: 'Message' },
  { icon: 'merge-cells', text: 'Merge Cells' },
  { icon: 'disconnect', text: 'Disconnect' },
  { icon: 'money-collect', text: 'Money Collect' },
  { icon: 'monitor', text: 'Monitor' },
  { icon: 'node-collapse', text: 'Node Collapse' },
  { icon: 'menu', text: 'Menu' },
  { icon: 'node-expand', text: 'Node Expand' },
  { icon: 'notification', text: 'Notification' },
  { icon: 'number', text: 'Number' },
  { icon: 'paper-clip', text: 'Paper Clip' },
  { icon: 'phone', text: 'Phone' },
  { icon: 'partition', text: 'Partition' },
  { icon: 'one-to-one', text: 'One To One' },
  { icon: 'pay-circle', text: 'Pay Circle' },
  { icon: 'percentage', text: 'Percentage' },
  { icon: 'picture', text: 'Picture' },
  { icon: 'pound-circle', text: 'Pound Circle' },
  { icon: 'play-square', text: 'Play Square' },
  { icon: 'poweroff', text: 'Poweroff' },
  { icon: 'pound', text: 'Pound' },
  { icon: 'project', text: 'Project' },
  { icon: 'printer', text: 'Printer' },
  { icon: 'property-safety', text: 'Property Safety' },
  { icon: 'mobile', text: 'Mobile' },
  { icon: 'pushpin', text: 'Pushpin' },
  { icon: 'pull-request', text: 'Pull Request' },
  { icon: 'profile', text: 'Profile' },
  { icon: 'qrcode', text: 'Qrcode' },
  { icon: 'reconciliation', text: 'Reconciliation' },
  { icon: 'read', text: 'Read' },
  { icon: 'red-envelope', text: 'Red Envelope' },
  { icon: 'more', text: 'More' },
  { icon: 'node-index', text: 'Node Index' },
  { icon: 'reload', text: 'Reload' },
  { icon: 'rocket', text: 'Rocket' },
  { icon: 'rotate-left', text: 'Rotate Left' },
  { icon: 'robot', text: 'Robot' },
  { icon: 'safety', text: 'Safety' },
  { icon: 'save', text: 'Save' },
  { icon: 'rotate-right', text: 'Rotate Right' },
  { icon: 'scan', text: 'Scan' },
  { icon: 'schedule', text: 'Schedule' },
  { icon: 'safety-certificate', text: 'Safety Certificate' },
  { icon: 'send', text: 'Send' },
  { icon: 'shake', text: 'Shake' },
  { icon: 'share-alt', text: 'Share Alt' },
  { icon: 'search', text: 'Search' },
  { icon: 'security-scan', text: 'Security Scan' },
  { icon: 'history', text: 'History' },
  { icon: 'rest', text: 'Rest' },
  { icon: 'shopping', text: 'Shopping' },
  { icon: 'sisternode', text: 'Sister node' },
  { icon: 'shop', text: 'Shop' },
  { icon: 'smile', text: 'Smile' },
  { icon: 'shopping-cart', text: 'Shopping Cart' },
  { icon: 'solution', text: 'Solution' },
  { icon: 'split-cells', text: 'Split Cells' },
  { icon: 'star', text: 'Star' },
  { icon: 'switcher', text: 'Switcher' },
  { icon: 'subnode', text: 'Sub node' },
  { icon: 'sync', text: 'Sync' },
  { icon: 'table', text: 'Table' },
  { icon: 'skin', text: 'Skin' },
  { icon: 'tag', text: 'Tag' },
  { icon: 'tags', text: 'Tags' },
  { icon: 'team', text: 'Team' },
  { icon: 'thunderbolt', text: 'Thunderbolt' },
  { icon: 'tool', text: 'Tool' },
  { icon: 'trademark-circle', text: 'Trademark Circle' },
  { icon: 'to-top', text: 'To Top' },
  { icon: 'trademark', text: 'Trademark' },
  { icon: 'transaction', text: 'Transaction' },
  { icon: 'trophy', text: 'Trophy' },
  { icon: 'sound', text: 'Sound' },
  { icon: 'unlock', text: 'Unlock' },
  { icon: 'setting', text: 'Setting' },
  { icon: 'ungroup', text: 'Ungroup' },
  { icon: 'select', text: 'Select' },
  { icon: 'upload', text: 'Upload' },
  { icon: 'tablet', text: 'Tablet' },
  { icon: 'user-delete', text: 'User Delete' },
  { icon: 'user', text: 'User' },
  { icon: 'usergroup-delete', text: 'User group Delete' },
  { icon: 'user-switch', text: 'User Switch' },
  { icon: 'verified', text: 'Verified' },
  { icon: 'video-camera-add', text: 'Video Camera Add' },
  { icon: 'usb', text: 'Usb' },
  { icon: 'wallet', text: 'Wallet' },
  { icon: 'video-camera', text: 'Video Camera' },
  { icon: 'usergroup-add', text: 'User group Add' },
  { icon: 'whats-app', text: 'Whats App' },
  { icon: 'woman', text: 'Woman' },
  { icon: 'translation', text: 'Translation' },
  { icon: 'wifi', text: 'Wifi' },
  { icon: 'user-add', text: 'User Add' },
];

//.reduce((acc, { icon, text }) => {
//  return `${acc}{ icon: '${icon}', text: '${text}' },\n`
//}, '');

