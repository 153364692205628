import { Pipe, PipeTransform } from '@angular/core';
import has from 'lodash/get';

@Pipe({
  name: 'has',
  standalone: true,
})
export class HasPipe implements PipeTransform {
  /**
   * Checks if path is a direct property of object.
   *
   * @param {*} data - The data to get the property from.
   * @param {string | string[]} path - The path of the property to get.
   * @returns {boolean} - True, if the data contains value at path.
   */
  public transform(data: any, path: string | string[]): any {
    return has(data, path);
  }
}
