import { Pipe, PipeTransform } from '@angular/core';
import { DEFAULT_SELECT, DYNAMIC_SELECT, DynamicSelectConfig, DynamicSelectControl, SelectConfig, SelectControl, SelectOption, SelectType } from './select.interface';

@Pipe({
  name: 'selectOptions',
  standalone: true,
})
export class SelectPipe implements PipeTransform {
  /**
   * Extract options from given control.
   *
   * @param {BaseControl} control - The value to transform.
   * @param {Record<string, any>} [data={}] - The data used in the form.
   * @returns {SelectOption[] | null | undefined} - The transformed value.
   */
  public transform(control: SelectControl, data: Record<string, any> = {}): SelectOption[] {
    if (control.config) {
      const type: SelectType = (control.config as SelectConfig).type;
      if (type === DEFAULT_SELECT) {
        return (control.config as SelectConfig).options;
      }
      if (type === DYNAMIC_SELECT) {
        const config: DynamicSelectConfig = control.config as DynamicSelectConfig;
        return config.getOptions(data, control as DynamicSelectControl);
      }
    }
    return [];
  }
}
