import { Pipe, PipeTransform } from '@angular/core';
import { TextManager } from '@smartops-monorepo/core-common';
import isString from 'lodash/isString';

@Pipe({
  name: 'inputValueReminder',
  standalone: true,
})
export class InputValueReminderPipe implements PipeTransform {
  /**
   * @param {unknown} value - The value to transform.
   * @returns {any} - The transformed value.
   */
  transform(value: string): any {
    return TextManager.createInputValueReminder(value);
  }
}

@Pipe({
  name: 'placeholder',
  standalone: true,
})
export class PlaceholderPipe implements PipeTransform {
  /**
   * @param {unknown} value - The value for which to create a placeholder.
   * @param {boolean} [hasDots=true] - Whether to add dots at the end of the placeholder.
   * @returns {any} - The transformed value.
   */
  transform(value: string, hasDots: boolean = true): any {
    return TextManager.createPlaceholder(value, hasDots);
  }
}

@Pipe({
  name: 'text',
  standalone: true,
  pure: true,
})
export class TextPipe implements PipeTransform {
  /**
   *
   * @param {any} textCode - The text code to get the text for.
   * @param {boolean} [capitalize=true] - Whether to capitalize the text.
   * @param {string} [defaultValue=''] - The default value to return if no text is found.
   * @returns {any} The transformed value.
   */
  transform(textCode: unknown, capitalize: boolean = true, defaultValue: unknown = ''): any {
    if (!isString(textCode) && !isString(defaultValue)) {
      return textCode;
    }
    const result = this.getText(textCode, defaultValue);
    return capitalize ? TextManager.capitalizeFirstLetter(result) : result;
  }

  getText(textCode: unknown, defaultValue: unknown): string {
    if (TextManager.isCorrectTextCode(textCode)) {
      return TextManager.getTextFromCode(textCode as string);
    }

    if (isString(textCode)) {
      return textCode;
    }

    if (TextManager.isCorrectTextCode(defaultValue)) {
      return TextManager.getTextFromCode(defaultValue as string);
    }
    return (defaultValue ?? '') as string;
  }

}
