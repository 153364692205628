import { PaginationDTO } from './pagination.dto';
import { ValidateNested } from 'class-validator';
import { Expose } from 'class-transformer';

export class FetchDataResultDTO<T> {
  items!: T[];
  pagination!: PaginationDTO;
}

export class BaseFetchDataResultDTO {
  constructor(pagination: PaginationDTO) {
    this.pagination = pagination;
  }

  @Expose()
  @ValidateNested()
  pagination!: PaginationDTO;
}
