import { NZ_ICONS } from 'ng-zorro-antd/icon';
import { en_US, NZ_I18N } from 'ng-zorro-antd/i18n';
import { AntIcons } from './ant-design.icons';


export const NzIconProvider = { provide: NZ_ICONS, useValue: AntIcons };
export const NzI18nProvider = { provide: NZ_I18N, useValue: en_US };

export type NzSpan = null
  | 0 | 1 | 2 | 3 | 4
  | 5 | 6 | 7 | 8
  | 9 | 10 | 11 | 12
  | 13 | 14 | 15 | 16
  | 17 | 18 | 19 | 20
  | 21 | 22 | 23 | 24;
