import { ChangeDetectionStrategy, Component, computed, input, InputSignal, model, ModelSignal, Signal, ViewEncapsulation } from '@angular/core';
import { CoreModule } from '@smartops-monorepo/ui-core';
import dayjs from 'dayjs';
import { FormsModule } from '@angular/forms';
import { NzOptionComponent, NzSelectComponent } from 'ng-zorro-antd/select';

const CURRENT_YEAR = dayjs().year();

type YearItem = { value: number; label: string; }
const getYearItem = (year: number): YearItem => ({
  value: year,
  label: year.toString(),
});

@Component({
  selector: 'spa-ui-year-select',
  standalone: true,
  imports: [CoreModule, NzSelectComponent, NzOptionComponent, FormsModule],
  templateUrl: './year-select.component.html',
  styleUrl: './year-select.component.scss',
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class YearSelectComponent {
  value: ModelSignal<number> = model.required();
  from: InputSignal<number> = input(CURRENT_YEAR - 2);
  till: InputSignal<number> = input(CURRENT_YEAR + 2);
  years: Signal<YearItem[]> = computed(() => {
    const from: number = this.from();
    const length: number = (this.till() - from) + 1;
    return Array.from({ length })
      .map((_, index) => from + index)
      .map(getYearItem);
  })
}
