import { Pipe, PipeTransform } from '@angular/core';
import { isBoolean, isFunction, isNil, isNumber, isString } from 'lodash';

@Pipe({
  name: 'isNil',
  standalone: true,
})
export class IsNilPipe implements PipeTransform {
  /**
   * Checks if the value is either null or undefined.
   *
   * @param {*} value - The value to check.
   * @param {boolean} [expected=true] - The value it should be.
   * @returns {boolean} True, if the value is either null or undefined.
   */
  public transform(value: any, expected: boolean = true): boolean {
    return isNil(value) === (isBoolean(expected) ? expected : true);
  }
}

@Pipe({
  name: 'isFunction',
  standalone: true,
})
export class IsFunctionPipe implements PipeTransform {
  /**
   * Checks if the value is classified as a Function object.
   *
   * @param {*} value - The value to check.
   * @returns {boolean} True, if the value is a function.
   */
  public transform(value: any): boolean {
    return isFunction(value);
  }
}

@Pipe({
  name: 'isNumber',
  standalone: true,
})
export class IsNumber implements PipeTransform {
  /**
   * Checks if the value is classified as a number object.
   *
   * @param {*} value - The value to check.
   * @returns {boolean} True, if the value is a number.
   */
  public transform(value: any): boolean {
    return isNumber(value);
  }
}

@Pipe({
  name: 'isString',
  standalone: true,
})
export class IsString implements PipeTransform {
  /**
   * Checks if the value is classified as a string object.
   *
   * @param {*} value - The value to check.
   * @returns {boolean} True, if the value is a string.
   */
  public transform(value: any): boolean {
    return isString(value);
  }
}

@Pipe({
  name: 'isTrue',
  standalone: true,
})
export class IsTruePipe implements PipeTransform {
  /**
   * Checks if the value/expression is true.
   *
   * @param {*} value - The value/expression to check.
   * @returns {boolean} True, if the value/expression is true.
   */
  public transform(value: any): boolean {
    return value === true;
  }
}

@Pipe({
  name: 'isFalse',
  standalone: true,
})
export class IsFalsePipe implements PipeTransform {
  /**
   * Checks if the value/expression is false.
   *
   * @param {*} value - The value/expression to check.
   * @returns {boolean} True, if the value/expression is false.
   */
  public transform(value: any): boolean {
    return value === false;
  }
}
