import { Pipe, PipeTransform } from '@angular/core';
import get from 'lodash/get';
import has from 'lodash/has';
import { NumberConfig, NumberControl } from '../number/number.interface';
import { BaseControl } from '../base/base-control.interface';
import { DateConfig, DatePickerControl } from '../date-picker/date-picker.interface';
import { TextareaConfig, TextareaControl } from '../textarea/textarea.interface';
import { TimeConfig, TimePickerControl } from '../time-picker/time-picker.interface';
import { TextControl } from '../text/text.interface';
import { DEFAULT_SELECT, DYNAMIC_SELECT, DynamicSelectControl, SelectControl } from '../select';


@Pipe({
  name: 'config',
  standalone: true,
})
export class ControlConfigPipe implements PipeTransform {
  /**
   * Extract options from given control.
   *
   * @param {BaseControl} control - The value to transform.
   * @param {string} property - The property name.
   * @returns {string} - The transformed value.
   */
  public transform(control: BaseControl, property: string): any {
    if (!has(control, `config.${property}`)) {
      return;
    }
    switch (control.type) {
      case 'date':
        return get(control.config as DateConfig, property);
      case 'number':
        return get(control.config as NumberConfig, property);
      case 'textarea':
        return get(control.config as TextareaConfig, property);
      case 'time':
        return get(control.config as TimeConfig, property);
      default:
        return get(control.config, property);
    }
  }
}


@Pipe({
  name: 'controlType',
  standalone: true,
})
export class ControlTypePipe implements PipeTransform {
  /**
   * Extract options from given control.
   *
   * @param {Control} control - The value to transform.
   * @returns {string} - The transformed value.
   */
  public transform(control: BaseControl): any {
    switch (control.type) {
      case 'date':
        return control as DatePickerControl;
      case 'number':
        return control as NumberControl;
      case 'select':
        switch (control.config.type) {
          case DYNAMIC_SELECT:
            return control as DynamicSelectControl;
          case DEFAULT_SELECT:
            return control as SelectControl;
          default:
            throw new Error(`Unknown select type: ${control.config.type}`);
        }
      case 'text':
        return control as TextControl;
      case 'textarea':
        return control as TextareaControl;
      case 'time':
        return control as TimePickerControl;
      default:
        return control as BaseControl;
    }
  }
}
