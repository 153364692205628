import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { StorageService } from './abstract-storage.service';

export const PATH = '/app';

@Injectable({ providedIn: 'platform' })
export class CookieStorageService extends StorageService {
  /**
   *
   * @param {CookieService} cookieService - The cookie service that will be used to store the values.
   */
  constructor(private cookieService: CookieService) {
    super();
  }

  /**
   * Retrieves the value stored under the key.
   *
   * @param {string} key - The key of the value to retrieve.
   * @returns {string | null} - The value stored under the key, or null if no value is stored.
   * @public
   * @override
   */
  public getItem(key: string): string | null {
    return this.cookieService.get(key);
  }

  /**
   * Removes the value stored under the key.
   *
   * @param {string} key - The key of the value to remove.
   * @public
   * @override
   */
  public removeItem(key: string): void {
    this.cookieService.delete(key, this.path);
  }

  /**
   * Stores a value under the given key.
   *
   * @param {string} key - The key to store the value under.
   * @param {string} value - The value to store.
   * @public
   * @override
   */
  public setItem(key: string, value: string): void {
    this.cookieService.set(key, value, { path: this.path, secure: true });
  }

  /**
   * Checks if a item is present in storage.
   *
   * @param {string} key - The key to check for.
   * @returns {boolean} - True if the key exists, false otherwise.
   * @public
   * @override
   */
  public hasItem(key: string): boolean {
    return this.cookieService.check(key);
  }

  /**
   * The path to store the cookie value under.
   *
   * @returns {string} - The path to store the value under in the cookie.
   * @private
   */
  private get path(): string {
    return PATH;
  }
}
