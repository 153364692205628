import { ChangeDetectionStrategy, Component, forwardRef, input, InputSignal, model, ModelSignal, ViewEncapsulation } from '@angular/core';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { ClientCommonModule } from '@smartops-monorepo/client-common';
import { BaseControlValueAccessor } from '../base';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'spa-ui-checkbox',
  standalone: true,
  imports: [ClientCommonModule, NzCheckboxModule],
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => CheckboxComponent),
    multi: true,
  }],
})
export class CheckboxComponent extends BaseControlValueAccessor {
  indeterminate: InputSignal<boolean> = input(false);
  disabled: ModelSignal<boolean> = model(false);

  override setDisabledState?(isDisabled: boolean): void {
    this.disabled.set(isDisabled);
  }
}
