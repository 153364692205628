import { inject } from '@angular/core';
import { CanActivateChildFn, CanActivateFn, CanMatchFn, Router, UrlTree } from '@angular/router';
import { AuthService, PlatformService } from '../service';

export const AuthGuard: CanActivateFn | CanActivateChildFn | CanMatchFn = async (
): Promise<boolean|UrlTree> => {
  const authService: AuthService = inject(AuthService);
  const platformService: PlatformService = inject(PlatformService);
  const router: Router = inject(Router);

  if (platformService.isRenderedOnServer() || authService.isAccessTokenValid()) {
    return true;
  }

  const refreshed = await authService.refreshAccessToken();
  if (refreshed) {
    return true;
  }

  await authService.logoutActions(false);
  return router.parseUrl('/login');
};
