import { PlaceholderText } from './interface';

export const DutchPlaceholderText: PlaceholderText = {
  name: 'Voer naam in',
  firstName: 'Voer voornaam in',
  lastName: 'Voer achternaam in',
  email: 'Voer uw email adres in',
  phone: 'Voer telefoon nummer toe',
  password: 'Voer wachtwoord in',
  address: 'Voer adres in',
  dateOfBirth: 'Voer geboortedatum in',
  dots: '...',
  enter_start: 'Voer',
  enter_end: 'in',
  title: 'Voer titel in',
  description: 'Voer beschrijving in',
  companyName: 'Voer bedrijfsnaam in',
}
