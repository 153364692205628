import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateChildFn, CanActivateFn, CanMatchFn, Data, Route, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { CurrentUserService, PlatformService } from '../service';
import { map, Observable, of } from 'rxjs';
import { get, isArray, isEmpty, some } from 'lodash';
import type { IUser, UserRole } from '@smartops-monorepo/types/user';

/**
 * Function that checks if the user has access to the domain.
 *
 * @param {UserRole[]} userRoles - The roles of the user.
 * @param {UserRole[]} roles - The roles that have access to the domain.
 * @return {boolean} - True, if the user has one of the required roles assigned to him.
 */
function hasRoleBasedAccess(userRoles: UserRole[], roles: UserRole[]): boolean {
  return some(roles, (roleThatHasAccess: UserRole) => (
    userRoles.includes(roleThatHasAccess)
  ));
}

/**
 * Function that checks if the user has access to the domain.
 *
 * @param {IUser | undefined} user - The user.
 * @param {UserRole[]} roles - The roles that have access to the domain.
 * @param {Router} router - The router.
 * @param {RouterStateSnapshot} state - The state of the router.
 * @return {boolean | UrlTree} - True, if the user has access to the domain, otherwise the UrlTree.
 */
export function checkIfUserHasAccessToDomain(user: IUser|undefined, roles: UserRole[], router: Router, state: RouterStateSnapshot): boolean|UrlTree {
//  console.log('[DomainGuard.getCurrentUser] data');
  const userRoles: UserRole[] = get(user, 'roles', []);

  if ((isEmpty(roles) && isEmpty(userRoles)) || hasRoleBasedAccess(userRoles, roles)) {
    return true;
  }

  const domain = CurrentUserService.getDomainToNavigateTo(userRoles);
  if (!state.url.startsWith(`/app/${domain}`)) {
    return router.parseUrl('/app');
  }

  return false;
}

/**
 * The main Domain Guard which checks if the current active user has access to the domain.
 * This is done by checking if they contain any of the roles needed to access the domain.
 * Which roles are needed to access the domain are defined on the route itself and is
 * provided through the route data.
 *
 * @param {ActivatedRouteSnapshot} route
 * @param {RouterStateSnapshot} state
 * @return {Observable<boolean | UrlTree>}
 * @constructor
 */
export const DomainGuard: CanActivateFn | CanActivateChildFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
): Observable<boolean|UrlTree> => {
  const router: Router = inject(Router);
  const currentUserService: CurrentUserService = inject(CurrentUserService);
  const platformService: PlatformService = inject(PlatformService);
  const roles: UserRole[] = route.data['roles'];

  if (platformService.isRenderedOnServer() || !isArray(roles) || isEmpty(roles)) {
    return of(true);
  }

  if (currentUserService.hasUser()) {
    return of(checkIfUserHasAccessToDomain(currentUserService.currentUser, roles, router, state));
  }

  return currentUserService.getCurrentUser().pipe(map((user: IUser) => (
    checkIfUserHasAccessToDomain(user, roles, router, state)
  )))
}

/**
 * The main Domain Guard which checks if the current active user has access to the domain.
 * This is done by checking if they contain any of the roles needed to access the domain.
 *
 * @param {Route} route - The route instance.
 * @return {Observable<boolean> | boolean} - The observable that resolves to true if the user has access to the domain, otherwise false.
 * @constructor
 */
export const CanMatchDomainGuard: CanMatchFn = (route: Route): Observable<boolean> | boolean => {
  const currentUserService: CurrentUserService = inject(CurrentUserService);
  const platformService: PlatformService = inject(PlatformService);

  if (platformService.isRenderedOnServer()) {
    return true;
  }

  const roles: UserRole[] = (route.data as Data)['roles'];
  return currentUserService.getCurrentUser().pipe(map((user: IUser) => {
    const userRoles: UserRole[] = get(user, 'roles', []);
    return hasRoleBasedAccess(userRoles, roles);
  }))
}
