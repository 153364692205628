/* eslint-disable @angular-eslint/no-output-on-prefix */
import { ControlValueAccessor } from '@angular/forms';
import { ChangeDetectorRef, Directive, effect, HostBinding, inject, input, InputSignal, output, OutputEmitterRef, signal, WritableSignal } from '@angular/core';
import { NzStatus } from 'ng-zorro-antd/core/types';
import { ControlSize } from './base-control.interface';
import noop from 'lodash/noop';


/**
 * Base class for all components that implement ControlValueAccessor.
 */
@Directive()
export abstract class BaseControlValueAccessor implements ControlValueAccessor {
  value: WritableSignal<any> = signal(undefined as any);
  onChange: (value: any) => void = noop;
  onTouched: () => void = noop;

  protected cd: ChangeDetectorRef = inject(ChangeDetectorRef);

  /**
   * Writes a new value to the element.
   *
   * @param {*} value - The value to write.
   */
  writeValue(value: any): void {
    this.value.set(value);
    this.onChange(value);
    this.cd.markForCheck();
  }

  /**
   * Registers a callback function that is called by the forms API on initialization to update the form model on blur.
   *
   * @param {*} fn – The callback function to register
   */
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  /**
   * Function that is called by the forms API when the control status changes to or from 'DISABLED'.
   * Depending on the status, it enables or disables the appropriate DOM element.
   *
   * @param {*} fn – The callback function to register
   */
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setDisabledState?(isDisabled: boolean): void {
    // Handle disabled state if applicable
  }

  /**
   * Handles all action needed when input value changes.
   *
   * @param {*} value - Thew new value.
   */
  onInput(value: any) {
    this.value.set(value);
    this.onChange(value);
  }
}

@Directive()
export abstract class BaseControlComponent extends BaseControlValueAccessor {

  disabled: InputSignal<boolean> = input<boolean>(false);
  readonly: InputSignal<boolean> = input<boolean>(false);

  placeholder: InputSignal<string|undefined> = input<string|undefined>(undefined as any);
  initialId: string|null = null;

  id: InputSignal<string|null> = input<string|null>(this.initialId);
  size: InputSignal<ControlSize> = input<ControlSize>('default');

  suffixIcon: InputSignal<string|undefined> = input(undefined as any);
  status: InputSignal<NzStatus> = input(undefined as any);

  keyDown: OutputEmitterRef<KeyboardEvent> = output<KeyboardEvent>();
  onFocus: OutputEmitterRef<void> = output<void>();

  hasFocus: InputSignal<boolean> = input<boolean>(false);

  protected constructor(protected testId: string) {
    super();

    effect(() => {
      const hasFocus = this.hasFocus();
      if (hasFocus) {
        setTimeout(() => {
          this.focus();
          this.cd.markForCheck();
        });
      }
    });
  }

  /**
   * Abstract function which should focus on the input field.
   * Needs to be implemented in child class
   *
   * @public
   * @abstract
   */
  abstract focus(): void;

  /**
   * Abstract function which should blur the input field.
   * Needs to be implemented in child class
   *
   * @public
   * @abstract
   */
  abstract blur(): void;

  /**
   *
   * @param {KeyboardEvent} event
   */
  public onKeyDown(event: KeyboardEvent): void {
    this.keyDown.emit(event);
    if (event.key === 'Tab') {
      this.onTabKey();
    }
  }

  /**
   * Handler for when user presses tab key inside input.
   */
  public onTabKey(): void {
    this.blur();
  }

  /**
   * Getter for data-testid which gets added to main component.
   *
   * @returns {string} - The data-testid.
   */
  @HostBinding('attr.data-testid')
  get dataTestId(): string {
    return this.testId;
  }
}
