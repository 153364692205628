import { IconDefinition } from '@ant-design/icons-angular';
import {
  AimOutline, AppstoreOutline, ArrowLeftOutline, ArrowRightOutline, AuditOutline, BankOutline, BgColorsOutline, BookFill, BookOutline, BookTwoTone, CheckCircleFill, CheckCircleOutline, CheckCircleTwoTone, CheckSquareOutline, CloseCircleOutline, CloseOutline, ClusterOutline, ContainerOutline, DashboardOutline, DatabaseOutline, DeleteOutline, DeploymentUnitOutline, EditOutline, ExperimentOutline, ExportOutline, EyeInvisibleOutline, EyeInvisibleTwoTone, EyeOutline, EyeTwoTone, FileExcelOutline, FilePdfOutline, FileProtectOutline, FilterOutline, FolderFill, FolderOpenFill, FolderOpenOutline, FolderOutline, FolderTwoTone, FontSizeOutline, FormOutline, FrownOutline, FrownTwoTone, FullscreenExitOutline, FullscreenOutline, GiftOutline, GlobalOutline, GroupOutline, HeartOutline, HomeOutline, IdcardOutline, InfoCircleOutline, IssuesCloseOutline, KeyOutline, LikeFill, LikeOutline, LinkOutline, LockOutline, LogoutOutline, MailOutline, ManOutline, MehOutline, MehTwoTone, MenuFoldOutline, MenuUnfoldOutline, MessageOutline, MinusCircleOutline, NotificationOutline, OrderedListOutline, PlusOutline, ProfileOutline, PushpinOutline, RadarChartOutline, ReadOutline, ReconciliationOutline, ReloadOutline, SaveOutline, SearchOutline, SecurityScanOutline, SettingOutline, SmileOutline, SmileTwoTone, SolutionOutline, StarOutline, TagOutline, TagsOutline, TeamOutline, ToolOutline, ToTopOutline, UnorderedListOutline, UserAddOutline, UsergroupAddOutline, UserOutline, WomanOutline,
} from '@ant-design/icons-angular/icons';

export const AntIcons: IconDefinition[] = [
  AppstoreOutline,
  AuditOutline,
  ClusterOutline,
  DashboardOutline,
  DeploymentUnitOutline,
  ExperimentOutline,
  GlobalOutline,
  HeartOutline,
  LogoutOutline,
  MessageOutline,
  MenuFoldOutline,
  MenuUnfoldOutline,
  NotificationOutline,
  ProfileOutline,
  ReconciliationOutline,
  SettingOutline,
  SecurityScanOutline,
  TeamOutline,
  UserOutline,
  SearchOutline,

  UserAddOutline,
  FileExcelOutline,
  LinkOutline,
  ReloadOutline,
  PushpinOutline,
  FilterOutline,
  DeleteOutline,
  SaveOutline,
  EditOutline,
  FilePdfOutline,
  IdcardOutline,
  MailOutline,

  PlusOutline,
  FullscreenExitOutline,
  CloseOutline,
  CloseCircleOutline,
  RadarChartOutline,

  CheckCircleOutline,
  CheckCircleTwoTone,
  CheckCircleFill,

  InfoCircleOutline,

  ArrowLeftOutline,
  ArrowRightOutline,
  CheckSquareOutline,
  DatabaseOutline,
  FolderOutline,
  FolderOpenFill,
  FontSizeOutline,
  MinusCircleOutline,
  OrderedListOutline,
  SolutionOutline,
  StarOutline,
  ToolOutline,
  UsergroupAddOutline,
  FolderOpenOutline,

  GiftOutline,
  WomanOutline,
  BankOutline,
  FolderOutline,

  BookOutline,
  BookFill,
  BookTwoTone,
  BgColorsOutline,
  LikeOutline, LikeFill,

  ManOutline,
  KeyOutline,
  FolderFill,
  FolderTwoTone,
  HomeOutline,
  CheckCircleTwoTone,
  TagsOutline,
  TagOutline, ToTopOutline,

  FullscreenOutline,
  FullscreenExitOutline,
  ExportOutline,

  FrownOutline,
  MehOutline,
  SmileOutline,

  FrownTwoTone,
  MehTwoTone,
  SmileTwoTone,

  ReadOutline,

  EyeOutline,
  EyeTwoTone,

  EyeInvisibleOutline,
  EyeInvisibleTwoTone,

  LockOutline,
  FileProtectOutline,
  ContainerOutline,
  FormOutline,

  UnorderedListOutline,
  AimOutline,
  GroupOutline,
  IssuesCloseOutline,
  CloseCircleOutline,
];
