import { PlaceholderText } from './interface';

export const EnglishPlaceholderText: PlaceholderText = {
  name: 'Enter name',
  firstName: 'Enter first name',
  lastName: 'Enter last name',
  email: 'Enter email',
  phone: 'Enter phone number',
  password: 'Enter password',
  address: 'Enter address',
  dateOfBirth: 'Enter date of birth',
  dots: '...',
  enter_start: 'Enter',
  enter_end: '',
  title: 'Enter title',
  description: 'Enter description',
  companyName: 'Enter company name',
}
