import { Pipe, PipeTransform } from '@angular/core';
import isFunction from 'lodash/isFunction';

export type HandlerType = ((item: any) => string | any) | undefined;

@Pipe({
  name: 'pipeFunction',
  standalone: true,
})
export class PipeFunction implements PipeTransform {
  /**
   * The pipe function pipe is used to transform a value using a function.
   *
   * @param {*} value - The value to transform and call the handler with.
   * @param {(value: any) => any} handler - The function to call.
   * @param {*} [context] - The context to bind to the function when calling it.
   * @returns {any} - The result of the function call.
   */
  public transform(value: any, handler: HandlerType, context?: any): any {
    if (!isFunction(handler)) {
      return value;
    }

    if (context) {
      return handler.call(context, value);
    }

    return handler(value);
  }
}
