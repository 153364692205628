import { NzSpan } from '@smartops-monorepo/ui-core/ant-design';

export interface InlineStyling {
  type: 'inline';
  hideLabel?: boolean;
  span: {
    label: NzSpan;
    control: NzSpan;
  };
}

export interface StackedStyling {
  type: 'stacked';
  hideLabel?: boolean;
}

export type LabelStyling = InlineStyling | StackedStyling;
export const DEFAULT_INLINE_STYLING: InlineStyling = {
  type: 'inline',
  span: { label: 6, control: 18 },
};

export interface ControlLayout {
  label: NzSpan;
  control: NzSpan;
  offset?: NzSpan;
}
