import { Pipe, PipeTransform } from '@angular/core';
import { size } from 'lodash';

@Pipe({
  name: 'isLast',
  standalone: true,
})
export class IsLastPipe implements PipeTransform {

  /**
   * Checks if the index is the same as the last index of the items.
   *
   * @param {number} index - The index to check.
   * @param {any[]} items - The items to check against.
   * @returns {boolean} True, if the index is the same as the last index of the items.
   */
  public transform(index: number, items: any[]): boolean {
    return index === size(items) - 1;
  }
}
