import { Pipe, PipeTransform } from '@angular/core';
import size from 'lodash/size';

@Pipe({
  name: 'size',
  standalone: true,
})
export class SizePipe implements PipeTransform {
  public transform(value: Record<string, any> | string | Array<any> | Set<any>): number {
    return size(value);
  }
}
