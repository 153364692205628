import { ChangeDetectionStrategy, Component, computed, HostBinding, input, InputSignal, Signal, ViewEncapsulation } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NzButtonModule, NzButtonShape, NzButtonSize, NzButtonType } from 'ng-zorro-antd/button';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import isString from 'lodash/isString';
import { CoreModule } from '@smartops-monorepo/client-common';

export type ButtonType = NzButtonType | 'danger' | 'warning'
export type RouterLink = string | string[] | undefined;

@Component({
  selector: 'spa-ui-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CoreModule, RouterModule, NzButtonModule],
})
export class ButtonComponent {

  type: InputSignal<ButtonType> = input('default' as ButtonType);
  shape: InputSignal<NzButtonShape> = input(null as NzButtonShape);
  size: InputSignal<NzButtonSize> = input('default' as NzButtonSize);
  routerLink: InputSignal<RouterLink|undefined> = input(undefined as any);
  disabled: InputSignal<boolean> = input(false);
  display: InputSignal<string|undefined> = input(undefined as any);
  styling: InputSignal<string|undefined> = input(undefined as any);

  @HostBinding('style.display') displayStyle: Signal<string|undefined> = computed(() => this.display());
  @HostBinding('attr.disabled') isDisabled: Signal<boolean> = computed(() => this.disabled());

  /**
   *
   * @type {Signal<boolean>}
   */
  block: Signal<boolean> = computed(() => this.display() === 'block');

  /**
   * Gets the correct nzType value based of the type input.
   *
   * @returns {Signal<NzButtonType>} - The nzType value.
   */
  nzType: Signal<NzButtonType> = computed(() => {
    const type = this.type();
    if (type === 'danger') {
      return 'primary';
    }
    if (type === 'warning') {
      return 'default';
    }
    return type;
  });

  /**
   * Returns true if the type is set to danger.
   *
   * @returns {Signal<boolean>} - True if the type is set to danger.
   */
  danger: Signal<boolean> = computed(() => (['danger', 'warning'].includes(this.type() as string)));


  /**
   * Returns true if the button is routable.
   *
   * @returns {Signal<boolean>} - True if the button is routable.
   */
  isRoutable: Signal<boolean> = computed(() => {
    const routerLink = this.routerLink();
    if (isNil(routerLink) || (!isString(routerLink) && !isArray(routerLink))) {
      return false;
    }
    return !isEmpty(this.routerLink);
  });

  /**
   * Getter for data-testid which gets added to main component.
   *
   * @returns {string} - The data-testid.
   */
  @HostBinding('attr.data-testid') testId: string = 'spa-ui-button';
}
