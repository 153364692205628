import { registerDecorator, ValidationArguments, ValidationOptions } from 'class-validator';
import { extractRelatedValue, isBeforeValidator } from './validation-helpers';

/**
 * Checks if the value is a date that's before the specified date.
 *
 * @param {string} property - The property to compare against.
 * @param {ValidationOptions} validationOptions - The validation options.
 * @returns {(object: Record<string, any>, propertyName: string) => void} - The decorator function.
 * @constructor
 */
export function IsBefore(property: string, validationOptions?: ValidationOptions): (object: Record<string, any>, propertyName: string) => void {
  return function (object: Record<string, any>, propertyName: string) {
    registerDecorator({
      name: 'IsBefore',
      target: object.constructor,
      propertyName: propertyName,
      constraints: [property],
      options: validationOptions,
      validator: {
        validate(value: any, args: ValidationArguments) {
          const relatedValue = extractRelatedValue(args);
          return isBeforeValidator(value, relatedValue);
        },
      },
    });
  };
}
