import { ChangeDetectionStrategy, Component, computed, input, InputSignal, Signal, ViewEncapsulation } from '@angular/core';
import isNil from 'lodash/isNil';
import { CoreModule } from '@smartops-monorepo/ui-core';
import { NzInputGroupComponent } from 'ng-zorro-antd/input';
import { ToAnyPipe } from '@smartops-monorepo/client-common/pipe';

@Component({
  selector: 'spa-ui-inline-input-icon-wrapper',
  standalone: true,
  imports: [CoreModule, NzInputGroupComponent, ToAnyPipe],
  template: `
    @if (hasIcons()) {
      <nz-input-group [nzPrefixIcon]="prefixIcon() | toAny" [nzSuffixIcon]="suffixIcon() | toAny">
        <ng-container [ngTemplateOutlet]="content"></ng-container>
      </nz-input-group>
    } @else {
      <ng-container [ngTemplateOutlet]="content"></ng-container>
    }
    
    <ng-template #content>
      <div class="inline-content">
        <ng-content></ng-content>
      </div>
    </ng-template>
  `,
  styleUrl: './inline-input-icon-wrapper.component.scss',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InlineInputIconWrapperComponent {
  prefixIcon: InputSignal<string|null> = input(null as any);
  suffixIcon: InputSignal<string|null> = input(null as any);

  hasIcons: Signal<boolean> = computed(() => {
    return !isNil(this.prefixIcon()) || !isNil(this.suffixIcon());
  });
}

