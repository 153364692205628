import { NgModule } from '@angular/core';
import { CoreModule } from '@smartops-monorepo/ui-core';
import { ButtonComponent } from './button';
import { CheckboxComponent } from './checkbox';
import { ControlComponent } from './control';
import { DatePickerComponent } from './date-picker';
import { IconComponent } from './icon';
import { NumberComponent } from './number';
import { SelectComponent } from './select';
import { TextComponent } from './text';
import { TextareaComponent } from './textarea';
import { TimePickerComponent } from './time-picker';
import { ConfirmModalService } from './modal';

const COMPONENTS = [
  ButtonComponent,
  CheckboxComponent,
  ControlComponent,
  DatePickerComponent,
  IconComponent,
  NumberComponent,
  SelectComponent,
  TextComponent,
  TextareaComponent,
  TimePickerComponent,
];

@NgModule({
  imports: [CoreModule, ...COMPONENTS],
  declarations: [],
  providers: [
    ConfirmModalService,
  ],
  exports: [CoreModule, ...COMPONENTS],
})
export class ComponentsModule {
}
