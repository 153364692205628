import { Pipe, PipeTransform } from '@angular/core';
import get from 'lodash/get';

@Pipe({
  name: 'get',
  standalone: true,
})
export class GetPipe implements PipeTransform {
  /**
   * Gets the value at path of object. If the resolved value is undefined, the defaultValue is returned in its place.
   *
   * @param {*} data - The data to get the property from.
   * @param {string | string[]} path - The path of the property to get.
   * @param {*} [defaultValue=undefined] - The defaultValue if the resolved value is undefined.
   * @returns {any} - The resolved value.
   */
  public transform(data: any, path: string | string[], defaultValue: any | undefined = undefined): any {
    return get(data, path, defaultValue);
  }
}
