@if (header()) {
  <div class="ant-card-head" [ngStyle]="headerStyling()" data-testid="card-header">
    <div class="ant-card-head-wrapper">
      <div class="ant-card-head-title">
        <ng-container *nzStringTemplateOutlet="header()">
          {{ header() }}
        </ng-container>
      </div>
    </div>
  </div>
}

@if (cover()) {
  <div class="ant-card-cover" data-testid="card-cover">
    <ng-template [ngTemplateOutlet]="cover()"></ng-template>
  </div>
}

<div class="ant-card-body"
  [ngStyle]="bodyStyling()"
  data-testid="card-body"
>
  <nz-skeleton [nzActive]="loading()" [nzLoading]="loading()" data-testid="card-loading">
    <ng-content></ng-content>
  </nz-skeleton>
</div>

@if (hasActions()) {
  <ul class="ant-card-actions" data-testid="card-actions" [ngStyle]="footerStyling()">
    @for (action of actions(); track action) {
      <li data-testid="card-action" [style.width.%]="100 / numberOfActions()">
          <span>
            <ng-template [ngTemplateOutlet]="action"></ng-template>
          </span>
      </li>
    }
  </ul>
}
