import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'platform' })
export abstract class StorageService {
  /**
   * Retrieves the value stored under the key.
   *
   * @param {string} key - The key of the value to retrieve.
   * @returns {string | null} - The value stored under the key, or null if no value is stored.
   * @public
   * @abstract
   */
  public abstract getItem(key: string): string | null;

  /**
   * Removes the value stored under the key.
   *
   * @param {string} key - The key of the value to remove.
   * @public
   * @abstract
   */
  public abstract removeItem(key: string): void;

  /**
   * Stores a value under the given key.
   *
   * @param {string} key - The key to store the value under.
   * @param {string} value - The value to store.
   * @public
   * @abstract
   */
  public abstract setItem(key: string, value: string): void;

  /**
   * Checks if a item is present in storage.
   *
   * @param {string} key - The key to check for.
   * @returns {boolean} - True if the key exists, false otherwise.
   * @public
   * @abstract
   */
  public abstract hasItem(key: string): boolean;
}
