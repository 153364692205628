import { ChangeDetectionStrategy, Component, computed, forwardRef, input, InputSignal, Signal, ViewChild, ViewEncapsulation } from '@angular/core';
import { BaseControlComponent } from '../base';
import { FocusDirective } from '@smartops-monorepo/ui-core/directive';
import { ClientCommonModule } from '@smartops-monorepo/client-common';
import { AutoSizeType, NzInputModule } from 'ng-zorro-antd/input';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'spa-ui-textarea',
  standalone: true,
  imports: [ClientCommonModule, NzInputModule, FocusDirective],
  templateUrl: './textarea.component.html',
  styleUrl: './textarea.component.scss',
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => TextareaComponent),
    multi: true,
  }],
})
export class TextareaComponent extends BaseControlComponent {
  @ViewChild(FocusDirective, { static: false }) input!: FocusDirective;

  minRows: InputSignal<number> = input(2);
  maxRows: InputSignal<number> = input(4);

  autosize: Signal<AutoSizeType | boolean | string> = computed(() => {
    if (!this.minRows() && !this.maxRows()) {
      return false;
    }
    return { minRows: this.minRows(), maxRows: this.maxRows() };
  });

  constructor() {
    super('textarea');
  }

  public focus(): void {
    this.input?.focus();
  }

  public blur(): void {
    this.input?.blur();
  }

}
