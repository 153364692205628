import { Expose, Type } from 'class-transformer';
import { IsDefined, IsOptional, ValidateNested } from 'class-validator';
import { CompanyDTO, JobDTO } from '../company';
import { PlanableJobDTO } from './planable-job.dto';
import { PaginationDTO } from '../table';
import { UserDTO } from '../user';
import { FetchShiftsResultDTO, PopulatedShiftDTO, ShiftDTO } from './shift.dto';
import { BaseShiftWorkerRecordDTO, ShiftWorkerRecordDTO } from './shift-worker.dto';
import { BaseFetchDataResultDTO } from '../table/fetch-data-result.dto';
import { ShiftAvailabilityDTO } from './shift-availability.dto';


export class PopulatedShiftWorkerRecordDTO extends BaseShiftWorkerRecordDTO {
  @Expose()
  @IsDefined()
  @ValidateNested()
  @Type(() => ShiftDTO)
  shift!: ShiftDTO;

  @Expose()
  @IsDefined()
  @ValidateNested()
  @Type(() => UserDTO)
  worker!: UserDTO;

  @Expose()
  @IsDefined()
  @ValidateNested()
  @Type(() => PlanableJobDTO)
  planablejob!: PlanableJobDTO;

  @Expose()
  @IsDefined()
  @ValidateNested()
  @Type(() => JobDTO)
  job!: JobDTO;

  @Expose()
  @IsDefined()
  @ValidateNested()
  @Type(() => CompanyDTO)
  employer!: CompanyDTO;
}

export class FetchShiftWorkerRecordsResultDTO extends BaseFetchDataResultDTO {
  constructor(items: PopulatedShiftWorkerRecordDTO[], pagination: PaginationDTO) {
    super(pagination);
    this.items = items;
  }

  @Expose()
  @ValidateNested({ each: true })
  @Type(() => PopulatedShiftWorkerRecordDTO)
  items!: PopulatedShiftWorkerRecordDTO[];
}

export class PopulatedShiftDetailDTO extends PopulatedShiftDTO {
  @Expose()
  @IsOptional()
  @ValidateNested({ each: true })
  @Type(() => ShiftWorkerRecordDTO)
  workers!: ShiftWorkerRecordDTO[];
}

export class FetchShiftsWorkerResultDTO extends FetchShiftsResultDTO {
  constructor(items: PopulatedShiftDTO[], pagination: PaginationDTO, availabilities: ShiftAvailabilityDTO[]) {
    super(items, pagination);
    this.items = items;
    this.availabilities = availabilities;
  }

  @Expose()
  @ValidateNested({ each: true })
  @Type(() => ShiftAvailabilityDTO)
  availabilities!: ShiftAvailabilityDTO[];
}
