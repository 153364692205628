import { ChangeDetectorRef, Directive, effect, ElementRef, input, InputSignal, signal, WritableSignal } from '@angular/core';
import isBoolean from 'lodash/isBoolean';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[spa-ui-focus]',
  exportAs: 'spa-ui-focus',
  standalone: true,
})
export class FocusDirective {

  private el: HTMLInputElement;
  private isFocussed: WritableSignal<boolean> = signal(false);

  hasFocus: InputSignal<boolean|undefined> = input(undefined as any);

  /**
   * @param {ElementRef} el - The element reference.
   * @param {ChangeDetectorRef} cd - The change detector reference.
   * @constructor
   */
  constructor(el: ElementRef, private cd: ChangeDetectorRef) {
    this.el = el.nativeElement as HTMLInputElement;
    effect(() => {

      if (isBoolean(this.hasFocus())) {
        if (this.hasFocus()) {
          this.focus();
        } else if (this.isFocussed()) {
          this.blur();
        }
      }
    });
  }

  /**
   * Focuses the element.
   */
  focus() {
    this.isFocussed.set(true);
    this.el.focus();
    this.cd.markForCheck();
    if (this.el.select) this.el.select();
  }

  /**
   * Blurs the element.
   */
  blur() {
    this.isFocussed.set(false);
    this.el.blur();
    this.cd.markForCheck();
  }

}
