import { Inject, Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CLIENT_CONFIG, ClientConfig } from '../config';

@Injectable({
  providedIn: 'root',
})
export class ApiPrefixInterceptor implements HttpInterceptor {
  /**
   * Constructor of the interceptor class.
   *
   * @param {ClientConfig} config - The client config values.
   */
  constructor(@Inject(CLIENT_CONFIG) private config: ClientConfig) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const { API_ENDPOINT, API_PREFIX } = this.config;
    if (!/^(http|https):/i.test(request.url)) {
      const extraSlash = request.url.startsWith('/') ? '' : '/';
      request = request.clone({ url: `${API_ENDPOINT}${API_PREFIX}${extraSlash}${request.url}` });
    }
    return next.handle(request);
  }
}
