import { InjectionToken } from '@angular/core';

export interface StorageKeys {
  ACCESS_TOKEN: string;
  REFRESH_TOKEN: string;
}

/**
 * The storage keys used when storing data in local storage.
 *
 * @type {{ACCESS_TOKEN: string, REFRESH_TOKEN: string}}
 */
export const STORAGE_KEYS_DEFAULT_VALUE: StorageKeys = {
  ACCESS_TOKEN: 'ACCESS_TOKEN',
  REFRESH_TOKEN: 'REFRESH_TOKEN',
};

export const STORAGE_KEYS = new InjectionToken<StorageKeys>('StorageKeys');
