import { IsMongoId, IsString, ValidateNested } from 'class-validator';
import { Expose } from 'class-transformer';
import { LocationDTO } from './location.dto';
import { Admin, Recruiter, RecruitmentClient, ShiftPlanner } from '@smartops-monorepo/types/user';

export class NewJobDTO {
  @Expose()
  @IsString()
  name!: string;
}

export class JobDTO extends NewJobDTO {
  @Expose({ groups: [Admin, Recruiter, RecruitmentClient, ShiftPlanner]})
  @IsMongoId()
  _id!: string;

  @ValidateNested()
  location!: LocationDTO;
}
