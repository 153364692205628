import { IsDateString, IsMongoId, IsOptional, IsString } from 'class-validator';
import { Expose } from 'class-transformer';
import { Admin, Recruiter, ShiftPlanner } from '@smartops-monorepo/types/user';

export class LocationDTO {
  @Expose({ groups: [Admin, Recruiter, ShiftPlanner] })
  @IsMongoId()
  _id!: string;

  @Expose({ groups: [Admin, ShiftPlanner] })
  @IsString()
  name!: string;

  @Expose()
  @IsString()
  address!: string;

  @Expose()
  @IsString()
  city!: string;

  @IsString()
  @IsOptional()
  state?: string;

  @Expose()
  @IsString()
  zipcode!: string;

  @Expose()
  @IsString()
  country!: string;

  @IsString()
  @IsOptional()
  full!: string;

  @IsOptional()
  @IsDateString()
  @Expose({ groups: [Admin, ShiftPlanner]})
  createdAt?: Date;

  @IsOptional()
  @IsDateString()
  @Expose({ groups: [Admin, ShiftPlanner]})
  updatedAt?: Date;
}
