import { ChangeDetectionStrategy, Component, computed, HostBinding, input, InputSignal, Signal, ViewEncapsulation } from '@angular/core';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { ClientCommonModule } from '@smartops-monorepo/client-common';
import isString from 'lodash/isString';

type IconThemeType = 'outline' | 'fill' | 'twotone';

@Component({
  selector: 'spa-ui-icon',
  standalone: true,
  imports: [ClientCommonModule, NzIconModule],
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconComponent {
  icon: InputSignal<string|null|undefined> = input(null as any);
  type: Signal<string> = computed(() => {
    return isString(this.icon()) ? this.icon() as string :  '';
  });
  theme: InputSignal<IconThemeType> = input('outline' as IconThemeType);
  twoToneColor: InputSignal<string> = input(null as any);
  rotate: InputSignal<number> = input(0);
  styling: InputSignal<Record<string, any>> = input({});
  hasOutlineConfiguration: Signal<boolean> = computed(() => {
    return this.theme() === 'outline' && isString(this.twoToneColor());
  });

@HostBinding('attr.data-testid') testId: string = 'icon';
}
