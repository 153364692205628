import { DUTCH_TEXT, ENGLISH_TEXT, Text, TEXT_TYPES, TextType } from './Text';
import { DUTCH, ENGLISH, Language } from './languages';
import get from 'lodash/get';
import isString from 'lodash/isString';

type TextCodeProps = { type: string, code: string };

/**
 * Class
 */
class TextManager {
  private language: Language = ENGLISH;

  /**
   * Switches the text to given language.
   *
   * @param {Language} language - The language to switch to.
   */
  public switchLanguage(language: Language) {
    if (![DUTCH, ENGLISH].includes(language)) {
      throw new Error(`The given language '${language}' is incorrect`);
    }
    if (this.language === language) {
      throw new Error(`The given language '${language}' is already active`);
    }
    this.language = language;
  }

  /**
   * Determines if the given type is correct.
   *
   * @param {string} type - The type to check.
   * @returns {boolean} - True, if the type is correct.
   */
  public isCorrectType(type: string): boolean {
    return TEXT_TYPES.includes(type as TextType);
  }

  /**
   * Gets the text object for the current language.
   *
   * @returns {Text} - The text object.
   */
  get text(): Text {
    return this.language === ENGLISH ? ENGLISH_TEXT : DUTCH_TEXT;
  }

  /**
   * Gets text for the given textCode.
   *
   * @param {string} textCode - The textCode.
   * @param {boolean} [capitalize=true] - Capitalize the text. Default is true.
   * @returns {any} - The text associated with the given textCode.
   */
  public getTextFromCode(textCode: string, capitalize: boolean = true): string {
    if (!isString(textCode) || !textCode.includes(':')) {
      return textCode;
    }

    const [type, code] = textCode.split(':');
    if (!this.isCorrectType(type)) {
      throw new Error(`The type '${type}' from the given text code is incorrect`);
    }

    const result: string = get<string>(this.text as any, `${type}.${code}`, textCode);
    return capitalize ? this.capitalizeFirstLetter(result) : result;
  }

  private getTypeAndCodeFromTextCode(textCode: string): TextCodeProps {
    const [type, code] = textCode.split(':');
    return { type, code };
  }

  /**
   * Determines if the given value is a correct text code.
   *
   * @param {unknown} value - The value to check.
   * @returns {boolean} - True, if the value is a correct text code.
   */
  public isCorrectTextCode(value: unknown): boolean {
    if (!isString(value) || !value.includes(':')) {
      return false;
    }
    const { type } = this.getTypeAndCodeFromTextCode(value as string);
    return this.isCorrectType(type);
  }

  /**
   * Capitalizes the given text.
   *
   * @param {string} text - The text to capitalize.
   * @returns {string} - The capitalized text.
   */
  public capitalizeFirstLetter(text: string): string {
    const firstLetter: string = text.charAt(0);
    const restOfText: string = text.slice(1);
    return `${firstLetter.toUpperCase()}${restOfText}`;
  }

  /**
   * Creates a form label from the given text code.
   *
   * @param {string} textCode - The text code.
   * @return {string} - The text with a capitalized first letter.
   */
  public createFormLabel(textCode: string): string {
    const text: string = this.getTextFromCode(textCode, true);
    return this.capitalizeFirstLetter(text);
  }

  public createInputValueReminder(textCode: string): string {
    if (!isString(textCode)) {
      return textCode;
    }
    const fieldName: string = this.getTextFromCode(textCode);
    const start = this.getTextFromCode('warning:pleaseEnterYour_start');
    const end = this.getTextFromCode('warning:pleaseEnterYour_end');
    return `${start} ${fieldName} ${end}`;
  }

  public createPlaceholder(textCode: string, hasDots: boolean = true): string {
    if (!isString(textCode)) {
      return textCode;
    }
    const fieldName: string = this.getTextFromCode(textCode);
    const start = this.getTextFromCode('placeholder:enter_start');
    const end = this.getTextFromCode('placeholder:enter_end');
    const text: string = `${start} ${fieldName} ${end}`;
    if (!hasDots) {
      return text;
    }
    const dots = this.getTextFromCode('placeholder:dots');
    return `${text.trim()} ${dots}`;
  }
}

export default new TextManager();
