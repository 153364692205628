import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'toAny',
  standalone: true,
})
export class ToAnyPipe implements PipeTransform {
  /**
   * Transform variable to any type.
   *
   * @param {*} value - The value to transform.
   * @returns {string} The transformed any value.
   */
  transform(value: unknown): any {
    return value as any
  }
}

@Pipe({
  name: 'toNumber',
  standalone: true,
})
export class ToNumberPipe implements PipeTransform {

  /**
   * Transform function to pipe a value to a number.
   *
   * @param {*} value - The value to transform.
   * @returns {string} The transformed string value.
   */
  transform(value: any): number {
    return Number.parseInt(value, 10);
  }
}

@Pipe({
  name: 'toString',
  standalone: true,
})
export class ToStringPipe implements PipeTransform {

  /**
   * Transform function to pipe a value to a string.
   *
   * @param {*} value - The value to transform.
   * @returns {string} The transformed string value.
   */
  transform(value: any): string {
    return value?.toString ? value?.toString() : `${value}`;
  }
}


@Pipe({
  name: 'toBoolean',
  standalone: true,
})
export class ToBooleanPipe implements PipeTransform {

  /**
   * Transform function to pipe a value to a string.
   *
   * @param {*} value - The value to transform.
   * @returns {boolean} The transformed boolean value.
   */
  transform(value: any): boolean {
    return Boolean(value);
  }

}
