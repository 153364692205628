<ng-template #content>
  <ng-content></ng-content>
</ng-template>

@if (!isRoutable()) {
  <button
    nz-button
    [nzType]="nzType()"
    [nzDanger]="danger()"
    [nzShape]="shape()"
    [disabled]="disabled()"
    [nzBlock]="block()"
    [style]="styling()"
    [nzSize]="size()"
    >
    <ng-container [ngTemplateOutlet]="content"></ng-container>
  </button>
} @else {
  <a nz-button
    [routerLink]="routerLink() | toAny"
    [nzType]="nzType()"
    [nzDanger]="danger()"
    [nzShape]="shape()"
    [disabled]="disabled()"
    [nzBlock]="block()"
    [style]="styling()"
    [nzSize]="size()"
    >
    <ng-container [ngTemplateOutlet]="content"></ng-container>
  </a>
}

