import { Pipe, PipeTransform } from '@angular/core';
import isEmpty from 'lodash/isEmpty';

@Pipe({
  name: 'memoize',
  standalone: true,
})
export class MemoizePipe implements PipeTransform {

  /**
   * The memoize pipe is used to memoize a function call.
   *
   * @param {() => any} fn - The function to memoize.
   * @param {any} context - The context to bind to the function when calling it.
   * @param {...any[]} args - The arguments to pass to the function.
   * @returns {any} - The result of the function call.
   */
  transform(fn: () => any, context: any, ...args: any): any {
    if (isEmpty(args)) {
      return fn.apply(context);
    }
    return fn.apply(context, args);
  }

}
