import { ShiftPlanner, Worker } from '@smartops-monorepo/types/user';
import { IsBoolean, IsDateString, IsDefined, IsMongoId, IsOptional, IsString, ValidateNested } from 'class-validator';
import { Expose, Type } from 'class-transformer';
import { UserDTO } from '../user';
import { CompanyDTO } from '../company';

export class BaseWorkerDTO {
  @Expose({ groups: [ShiftPlanner] })
  @IsOptional()
  @IsBoolean()
  active!: boolean;

  @Expose({ groups: [ShiftPlanner, Worker] })
  @IsDefined()
  @IsString()
  address!: string;

  @Expose({ groups: [ShiftPlanner, Worker] })
  @IsDefined()
  @IsDateString()
  dateOfBirth!: string;
}

export class NewWorkerDTO extends BaseWorkerDTO {
  @IsDefined()
  @IsMongoId()
  user!: string;

  @IsDefined()
  @IsMongoId()
  planningCompany!: string;
}

export class SimpleWorkerDTO extends BaseWorkerDTO {
  @IsMongoId()
  @IsDefined()
  _id!: string;

  @Expose({ groups: [ShiftPlanner, Worker] })
  @IsDefined()
  @ValidateNested()
  @Type(() => UserDTO)
  user!: UserDTO;

  @IsDefined()
  @IsMongoId()
  planningCompany!: string;

  @Expose({ groups: [ShiftPlanner] })
  @IsOptional()
  @IsDateString()
  createdAt!: string;

  @Expose({ groups: [ShiftPlanner] })
  @IsOptional()
  @IsDateString()
  updatedAt!: string;
}

export class WorkerDTO extends BaseWorkerDTO {
  @IsMongoId()
  @IsDefined()
  _id!: string;

  @Expose({ groups: [ShiftPlanner, Worker] })
  @IsDefined()
  @ValidateNested()
  @Type(() => UserDTO)
  user!: UserDTO;

  @Expose({ groups: [ShiftPlanner, Worker] })
  @IsOptional()
  @ValidateNested()
  @Type(() => CompanyDTO)
  planningCompany!: CompanyDTO;

  @Expose({ groups: [ShiftPlanner] })
  @IsOptional()
  @IsDateString()
  createdAt!: string;

  @Expose({ groups: [ShiftPlanner] })
  @IsOptional()
  @IsDateString()
  updatedAt!: string;
}
