import { IsBoolean, IsDefined, IsMongoId, IsOptional, IsString, ValidateNested } from 'class-validator';
import { Expose, Type } from 'class-transformer';
import { CompanyDTO, JobDTO, LocationDTO, NewJobDTO } from '../company';
import { ShiftPlanner } from '@smartops-monorepo/types/user';
import { BaseFetchDataResultDTO } from '../table/fetch-data-result.dto';
import { PaginationDTO } from '../table';


export class BasePlanableJobDTO {
  @Expose()
  @IsString()
  @IsOptional()
  description?: string;

  @Expose({ groups: [ShiftPlanner] })
  @IsBoolean()
  @IsOptional()
  active!: boolean;

  @Expose()
  @IsString()
  color!: string;

  @Expose()
  @IsString()
  @IsOptional()
  icon!: string;
}

export class CreateNewPlanableJobDTO extends BasePlanableJobDTO {
  @Expose({ groups: [ShiftPlanner] })
  @ValidateNested()
  @Type(() => NewJobDTO)
  job!: NewJobDTO;

  @Expose({ groups: [ShiftPlanner] })
  @ValidateNested()
  @Type(() => LocationDTO)
  location!: LocationDTO;
}

export class NewPlanableJobDTO extends BasePlanableJobDTO {
  @Expose({ groups: [ShiftPlanner] })
  @ValidateNested()
  @Type(() => NewJobDTO)
  job!: NewJobDTO;

  @Expose({ groups: [ShiftPlanner] })
  @ValidateNested()
  @Type(() => LocationDTO)
  location!: LocationDTO;

  @Expose({ groups: [ShiftPlanner] })
  @ValidateNested()
  @Type(() => CompanyDTO)
  employer!: CompanyDTO;
}

export class CreatedPlanableJobDTO extends BasePlanableJobDTO {
  @Expose()
  @IsDefined()
  @IsMongoId()
  _id!: string;

  @Type(() => JobDTO)
  @ValidateNested()
  job!: JobDTO;

  @IsMongoId() location!: string;
  @IsMongoId() planner!: string;
  @IsMongoId() employer!: string;
}

export class PlanableJobDTO extends NewPlanableJobDTO {
  @Expose()
  @IsDefined()
  @IsMongoId()
  _id!: string;

  @Expose({ groups: [ShiftPlanner] })
  @ValidateNested()
  @Type(() => CompanyDTO)
  planner!: CompanyDTO;
}

export class FetchPlanableJobsDTO extends BaseFetchDataResultDTO {
  constructor(items: PlanableJobDTO[], pagination: PaginationDTO) {
    super(pagination);
    this.items = items;
  }

  @Expose()
  @ValidateNested({ each: true })
  @Type(() => PlanableJobDTO)
  items!: PlanableJobDTO[];
}

