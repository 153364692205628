import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'iterable',
  standalone: true,
})
export class IterablePipe implements PipeTransform {
  /**
   * Transform function to pipe a value to a string.
   *
   * @param {*} value - The value to transform.
   * @returns {boolean} The transformed boolean value.
   */
  transform(value: any): Iterable<any> {
    return value as Iterable<any>
  }

}
