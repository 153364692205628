import { ChangeDetectionStrategy, Component, computed, HostBinding, input, InputSignal, Signal, TemplateRef, ViewEncapsulation } from '@angular/core';
import { NzStringTemplateOutletDirective } from 'ng-zorro-antd/core/outlet';
import { NzSkeletonComponent } from 'ng-zorro-antd/skeleton';
import { NgStyleInterface } from 'ng-zorro-antd/core/types';
import { CoreModule } from '@smartops-monorepo/client-common';
import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import isUndefined from 'lodash/isUndefined';
import set from 'lodash/set';
import size from 'lodash/size';

@Component({
  selector: 'spa-ui-card',
  standalone: true,
  imports: [CoreModule, NzSkeletonComponent, NzStringTemplateOutletDirective],
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CardComponent {
  cover: InputSignal<TemplateRef<void>|null> = input(null as any);

  headerStyle: InputSignal<NgStyleInterface> = input({});
  bodyStyle: InputSignal<NgStyleInterface> = input({});

  header: InputSignal<string | TemplateRef<void> | undefined> = input(undefined as any);
  actions: InputSignal<TemplateRef<void>[]> = input([] as TemplateRef<any>[]);
  borderRadius: InputSignal<number> = input(2);
  borderRadiusValue: Signal<string> = computed(() => `${this.borderRadius()}px`);
  headerStyling: Signal<NgStyleInterface> = computed(() => {
    const borderRadius = this.borderRadiusValue();
    const headerBorderRadius = this.hasHeader() ? { borderTopLeftRadius: borderRadius, borderTopRightRadius: borderRadius } : {};
    return { ...(this.headerStyle() || {}), ...headerBorderRadius };
  });

  bodyStyling: Signal<NgStyleInterface> = computed(() => {
    const borderRadius = this.borderRadiusValue();
    const body = this.bodyStyle() ?? {};
    if (!this.hasHeader()) {
      set(body, 'borderTopLeftRadius', borderRadius);
      set(body, 'borderTopRightRadius', borderRadius);
    }
    if (!this.hasActions()) {
      set(body, 'borderBottomLeftRadius', borderRadius);
      set(body, 'borderBottomRightRadius', borderRadius);
    }
    return body;
  });

  footerStyling: Signal<NgStyleInterface> = computed(() => {
    const borderRadius = this.borderRadiusValue();
    return this.hasActions() ? { borderBottomLeftRadius: borderRadius, borderBottomRightRadius: borderRadius } : {};
  });

  loading: InputSignal<boolean> = input<boolean>(false);

  @HostBinding('class') get classList(): string {
    return classNames('ant-card', {
      'ant-card-loading': this.loading(),
      'has-card-header': !isNil(this.header()),
      'has-card-actions': !isEmpty(this.actions())
    });
  }

  @HostBinding('style.border-radius') get provideBorderRadiusStyle(): string {
    return this.borderRadiusValue();
  }

  hasHeader: Signal<boolean> = computed(() => !isUndefined(this.header()));

  numberOfActions: Signal<number> = computed(() => {
    return size(this.actions());
  });

  hasActions:Signal<boolean> = computed(() => {
    return this.numberOfActions() !== 0
  });

  /**
   * Getter for data-testid which gets added to main component.
   *
   * @returns {string} - The data-testid.
   */
  @HostBinding('attr.data-testid') testId: string = 'card';
}
