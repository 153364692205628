import type { IPlanableJob } from '@smartops-monorepo/types/shift-planner';
import type { ICompany, IJob } from '@smartops-monorepo/types/company';
import type { ILocation } from '@smartops-monorepo/types/location';
import { PopulatedShiftDTO } from '../dto';

const address: string = 'Address Lane 1';
const city: string = 'City Place';
const zipcode: string = '1234AB';
const country: string = 'The Country Land'
const location: ILocation = { _id: 'id', name: 'location', address, city, zipcode, country };

const job: IJob = { name: 'Name of the Job' };
const description = 'The Description Text';
const planableJob: IPlanableJob = { description, job, location }  as IPlanableJob;
const planner: ICompany = { name: 'Planner Company Name' } as ICompany;
const employer: ICompany = { name: 'Employer Company Name' } as ICompany;
const date = new Date('2030-06-01');
const startTime = new Date(new Date(date).setHours(9, 0, 0, 0));
const endTime = new Date(new Date(date).setHours(18, 0, 0, 0));

export const ShiftTestData = {
  address,
  city,
  zipcode,
  country,
  location,
  job,
  description,
  planableJob,
  planner,
  employer,
  date,
  startTime,
  endTime,
}

export function createShift(_id: string = '1', breakDuration: number = 30, numberOfWorkers: number = 1, registeredWorkers: number = 0): PopulatedShiftDTO {
  return { _id, breakDuration, numberOfWorkers, planner, registeredWorkers, date, startTime, endTime, employer, planableJob, job, location, availableSpots: numberOfWorkers - registeredWorkers  } as unknown as PopulatedShiftDTO;
}
