import { InjectionToken } from '@angular/core';

export interface ClientConfig {
  API_ENDPOINT: string;
  API_PREFIX: string;
  WEBSITE_URL: string;
}

export const CLIENT_CONFIG = new InjectionToken<ClientConfig>('ClientConfig');

