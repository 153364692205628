import { ChangeDetectionStrategy, Component, computed, forwardRef, Signal, signal, ViewChild, ViewEncapsulation, WritableSignal } from '@angular/core';
import { AntDesignIcons, IconOption } from './icons.constant';
import { CoreModule } from '@smartops-monorepo/client-common';
import { IconComponent } from '../icon';
import { TextComponent } from '../text';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { BaseControlComponent } from '../base';
import { NzPopoverDirective } from 'ng-zorro-antd/popover';
import { IconPickerContentComponent } from './icon-picker-content.component';
import { ButtonComponent } from '../button';

@Component({
  selector: 'spa-ui-icon-picker',
  standalone: true,
  imports: [CoreModule, IconComponent, TextComponent, NzPopoverDirective, IconPickerContentComponent, ButtonComponent],
  templateUrl: './icon-picker.component.html',
  styleUrls: ['./icon-picker.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => IconPickerComponent),
    multi: true,
  }],
})
export class IconPickerComponent extends BaseControlComponent {
  @ViewChild(NzPopoverDirective, { static: false }) popover!: NzPopoverDirective;

  searchValue: WritableSignal<string> = signal('');

  options: Signal<IconOption[]> = computed(() => {
    const searchValue = this.searchValue();
    return AntDesignIcons.filter(({ text, icon }: IconOption) => (
      text.includes(searchValue) || icon.includes(searchValue)
    ));
  });

  constructor() {
    super('icon-picker');
  }

  public blur(): void {
    this.popover?.hide();
  }

  public focus(): void {
//    this.popover.show();
  }

  onSelection(option: IconOption): void {
    this.onInput(option.icon);
    this.blur();
  }


}
