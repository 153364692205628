import { ChangeDetectionStrategy, Component, computed, HostBinding, input, InputSignal, Signal, ViewEncapsulation } from '@angular/core';
import isNil from 'lodash/isNil';
import isUndefined from 'lodash/isUndefined';
import { NzColDirective, NzRowDirective } from 'ng-zorro-antd/grid';
import { NzFormControlComponent, NzFormItemComponent, NzFormLabelComponent } from 'ng-zorro-antd/form';
import { ToAnyPipe } from '@smartops-monorepo/client-common/pipe';
import { NzSpan } from '@smartops-monorepo/ui-core/ant-design';
import { CoreModule } from '@smartops-monorepo/ui-core';
import { ControlLayout } from '../control';

@Component({
  selector: 'spa-ui-input-wrapper',
  standalone: true,
  imports: [CoreModule, NzColDirective, NzFormControlComponent, NzFormLabelComponent, ToAnyPipe, NzFormItemComponent, NzRowDirective],
  template: `
    <nz-form-item>
      <nz-form-label
        data-testid="form-label"
        [nzRequired]="required()"
        [nzSpan]="label() | toAny"
        style="max-height: 32px;"
      >
        {{ labelText() }}
      </nz-form-label>
      
      <nz-form-control
        data-testid="form-control"
        [nzSpan]="control() | toAny"
        [nzOffset]="offset() | toAny">
        <ng-content></ng-content>
      </nz-form-control>
    </nz-form-item>
  `,
  styles: `
    spa-ui-input-wrapper.stacked {
        display: flex;
        flex-direction: column;
    }

    spa-ui-input-wrapper > nz-form-item.ant-form-item {
        margin-bottom: 0;
    }
  `,
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputWrapperComponent {
  labelText: InputSignal<string|null> = input(null as any);
  layout: InputSignal<ControlLayout | undefined> = input(undefined as any);
  type: InputSignal<'inline'|'stacked'|null> = input('stacked' as any);

  required: InputSignal<boolean> = input<boolean>(false);
  isStacked: Signal<boolean> = computed(() => this.type() === 'stacked');
  isInline: Signal<boolean> = computed(() => this.type() === 'inline');

  @HostBinding('class') get class(): string {
    return this.isStacked() ? 'stacked' : 'inline';
  }

  label: Signal<NzSpan|null> = computed(() => {
    const label: NzSpan|undefined = this.layout()?.label;
    if (this.isStacked()) return 24;
    if (this.isInline() && this.isCorrectSpan(label)) return label as NzSpan;
    return null;
  });

  control: Signal<NzSpan|null> = computed(() => {
    const control: NzSpan|undefined = this.layout()?.control;
    if (this.isStacked()) return 24;
    if (this.isInline() && this.isCorrectSpan(control)) return control as NzSpan;
    return null;
  });

  offset: Signal<NzSpan|null> = computed(() => {
    const offset: NzSpan|undefined = this.layout()?.offset;
    if (this.isStacked() || !this.isCorrectSpan(offset)) return null;
    return (this.isCorrectSpan(offset) ? offset : null) as NzSpan|null;
  });

  isCorrectSpan(span: NzSpan|undefined): boolean {
    if (isUndefined(span) || isNil(span) || isNaN(span)) return false;
    return span >= 0 && span <= 24;
  }

//  control: Signal<NzSpan|null> = computed(() => {
//    const control: NzSpan|undefined = this.layout()?.control;
//    if (this.isStacked()) return 24;
//    if (this.isInline()) {
//      if (this.isCorrectSpan(control) && control === 24) return 24;
//      const label: NzSpan|null = this.label();
//      const offset: NzSpan|null = this.offset();
//      if (this.isCorrectSpan(control)) return control;
//    }
//    if (isNil(control)) {
//      if (isNil(label) || !this.isCorrectSpan(label) || label === 24) return 24;
//      return (24 - label) as NzSpan;
//    }
//    return !isNil(control) ? control : null;
//  });
}

