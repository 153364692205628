import { Expose } from 'class-transformer';
import { IsBoolean, IsDateString, IsDefined, IsMongoId, IsOptional } from 'class-validator';
import { ShiftPlanner } from '@smartops-monorepo/types/user';
import { BaseShiftWorkerRecordDTO } from './shift-worker.dto';

export class BaseShiftAvailabilityDTO {
  @IsMongoId()
  @IsOptional()
  _id!: string;

  @Expose()
  @IsDefined()
  @IsBoolean()
  available!: boolean;

  @Expose({ groups: [ShiftPlanner] })
  @IsOptional()
  @IsDateString()
  createdAt!: string;

  @Expose({ groups: [ShiftPlanner] })
  @IsOptional()
  @IsDateString()
  updatedAt!: string;
}

export class ShiftAvailabilityDTO extends BaseShiftAvailabilityDTO {
  @Expose()
  @IsDefined()
  @IsMongoId()
  shift!: string;

  @Expose({ groups: [ShiftPlanner] })
  @IsDefined()
  @IsMongoId()
  user!: string;
}

export class WorkerShiftAvailabilityDTO extends BaseShiftWorkerRecordDTO {
  @Expose()
  @IsDefined()
  @IsMongoId()
  shift!: string;
  
  @Expose()
  @IsDefined()
  @IsBoolean()
  available!: boolean;
}
