import { ChangeDetectionStrategy, Component, forwardRef, input, InputSignal, ViewChild, ViewEncapsulation } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { NzInputModule } from 'ng-zorro-antd/input';
import { FocusDirective } from '@smartops-monorepo/ui-core/directive';
import { ClientCommonModule } from '@smartops-monorepo/client-common';
import { BaseControlComponent } from '../base';

@Component({
  selector: 'spa-ui-text',
  standalone: true,
  imports: [ClientCommonModule, NzInputModule, FocusDirective],
  templateUrl: './text.component.html',
  styleUrls: ['./text.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => TextComponent),
    multi: true,
  }],
})
export class TextComponent extends BaseControlComponent {
  @ViewChild(FocusDirective, { static: false }) input!: FocusDirective;

  minLength: InputSignal<number|null> = input(null as any);
  maxLength: InputSignal<number|null> =input(null as any);

  constructor() {
    super('text');
  }

  public focus(): void {
    this.input?.focus();
  }

  public blur(): void {
    this.input?.blur();
  }

}
