import { SelectOption } from './select.interface';
import get from 'lodash/get';
import isFunction from 'lodash/isFunction';
import isString from 'lodash/isString';
import map from 'lodash/map';


type GetFieldFn = (item: any) => any;

export function selectOptionsCreator(items: any[], labelPath: string | GetFieldFn, valuePath?: string): SelectOption[] {
  return map(items, (item: any) => ({
    label: isFunction(labelPath) ? labelPath(item) : get(item, labelPath),
    value: isString(valuePath) ? get(item, valuePath) : item,
  }))
}
