import { inject } from '@angular/core';
import { CanActivateChildFn, CanActivateFn, Router, UrlTree } from '@angular/router';
import { map, Observable, of } from 'rxjs';
import get from 'lodash/get';
import type { IUser, UserRole } from '@smartops-monorepo/types/user';
import { ShiftPlanner, Worker } from '@smartops-monorepo/types/user';
import { CurrentUserService, PlatformService } from '../service';

export function getDomainToNavigateToFromRoles(roles: UserRole[]): string {
  if (roles.includes(ShiftPlanner)) {
    return '/app/shift-planner';
  }
  if (roles.includes(Worker)) {
    return '/app/worker';
  }
  return `/app/${roles[0]}`;
}

export function getUrlToNavigateToIfNotAlreadyLocatedAtUrl(
  userRoles: UserRole[], router: Router,
): boolean | UrlTree {
  const navigateTo = getDomainToNavigateToFromRoles(userRoles);
  return router.parseUrl(navigateTo);
//  return !state.url.startsWith(navigateTo) || router.parseUrl(navigateTo);
}

export const RoleNavigationGuard: CanActivateFn | CanActivateChildFn = (
): Observable<boolean|UrlTree> => {
  const currentUserService: CurrentUserService = inject(CurrentUserService);
  const router: Router = inject(Router);
  const platformService: PlatformService = inject(PlatformService);

  if (platformService.isRenderedOnServer()) {
    return of(true);
  }

  return currentUserService.getCurrentUser().pipe(map((user: IUser) => {
    const userRoles: UserRole[] = get(user, 'roles', []);
    return getUrlToNavigateToIfNotAlreadyLocatedAtUrl(userRoles, router);
  }));
}
