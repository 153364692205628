import { LabelText } from './interface';

export const EnglishLabelText: LabelText = {
  name: 'name',
  firstName: 'first name',
  lastName: 'last name',
  email: 'email',
  phone: 'phone',
  password: 'password',
  welcomeUser: 'welcome user',
  loginToYourAccount: 'sign in to your account to continue',
  title: 'title',

  date: 'date',
  dateOfBirth: 'date of birth',
  birthday: 'birthday',
  description: 'description',

  start: 'start',
  startTime: 'start time',
  end: 'end',
  endTime: 'end time',
  break: 'break',
  breakDuration: 'break duration',
  actually: 'actually',

  address: 'address',
  city: 'city',
  state: 'state',
  zipcode: 'zipcode',
  country: 'country',

  edit: 'edit',
  editWorker: 'edit worker',
  login: 'login',
  logout: 'logout',
  confirm: 'confirm',
  confirmLogout: 'Do you want to logout?',
  confirmAction: 'confirm action',
  doYouWantToStop: 'Do you want to stop?',
  youHaveAlreadySelectedItemsThisWillClearYourSelection: 'You have already selected items. This will clear your selection!',
  doYouWantToStopSelectingShifts: 'Do you want to stop selecting shifts?',
  selectedItems: 'selected items',
  areYouSureYouWantToApproveThese: 'Are you sure you want to approve these?',
  areYouSureYouWantToRejectThese: 'Are you sure you want to reject these?',

  register: 'register',
  registerNow: 'register me',
  rememberMe: 'remember me',
  forgotPassword: 'forgot password',
  or: 'or',

  search: 'search',
  refresh: 'refresh',
  clear: 'clear',

  authenticate: 'authenticate',
  haveRead: 'I have read the',
  agreement: 'agreement',
  confirmPassword: 'Confirm Password',
  pleaseConfirmPassword: 'Please confirm your password!',
  passwordsDoNotMatch: 'The passwords that you enter do not match!',
  registrationSuccessfulMessage: 'The registration was successful!',
  profile: 'profile',

  actionWasSuccessful: 'The action was successful',

  shiftPlanner: 'Shift Planner',
  dashboard: 'dashboard',
  overview: 'overview',
  show: 'show',
  showDetails: 'show details',
  approve: 'approve',
  actions: 'actions',
  added: 'added',
  assigned: 'assigned',
  accepted: 'accepted',
  applied: 'applied',
  apply: 'apply',
  declined: 'declined',
  reject: 'reject',
  rejected: 'rejected',
  available: 'available',
  availability: 'availability',
  unavailable: 'unavailable',
  notAvailable: 'not available',
  undo: 'undo',

  create: 'create',
  creator: 'creator',
  createNew: 'create new',
  import: 'import',
  save: 'save',
  cancel: 'cancel',
  remove: 'remove',
  yes: 'yes',
  no: 'no',

  collection: 'collection',
  collections: 'collections',
  collectionOverview: 'collection overview',

  employer: 'employer',
  employers: 'employers',

  job: 'job',
  jobs: 'jobs',
  company: 'company',
  companies: 'companies',

  employee: 'employee',
  employees: 'employees',

  planner: 'planner',
  planners: 'planners',

  worker: 'worker',
  workers: 'workers',

  shift: 'shift',
  shifts: 'shifts',

  workerShift: 'worker shift',
  workerShifts: 'worker shifts',

  shiftCreator: 'Shifts Creator',
  shiftDetails: 'shift details',

  shiftWorkerRecord: 'Worked-Shift',
  shiftWorkerRecords: 'Worked-Shifts',

  numberOfWorkers: 'number of workers',

  location: 'location',
  newLocation: 'new location',

  insight: 'insight',
  details: 'details',
  info: 'info',

  calendar: 'calendar',
  accept: 'accept',
  add: 'add',
  decline: 'decline',
  deny: 'deny',

  nameJob: 'Name Job',
  from: 'from',
  till: 'till',

  status: 'status',
  selectAll: 'select all',
  selectNone: 'select none',

  registeredShifts: 'Registered Shifts',
  numberOfWorkedHoursThisMonth: 'Number of Worked Hours This Month',
  workedHours: 'Worked Hours',
  hoursLeftToRegister: 'Hours Left To Register',
  registerForNewShift: 'Register for new shift',
  viewYourHistory: 'View your history',
  registerHours: 'Register hours',
  upcomingShifts: 'Upcoming Shifts',
  noPlannedShiftsYetMessage: 'You have no planned shifts yet.',
  scheduleYourFirstShiftRightAway: 'Schedule your first shift right away!',
  scheduleShift: 'Schedule Shift',

  open: 'open',
  registered: 'registered',
  planned: 'planned',
  scheduled: 'scheduled',
  standBy: 'stand-by',
  all: 'all',
  history: 'history',
  history__EN: 'history',
  sortBy: 'sort by',
  today: 'today',
  completed: 'completed',

  week: 'week',
  total: 'total',
  difference: 'difference',
  deviation: 'deviation',
  timeRegistration: 'time registration',
  hourOverview: 'hour overview',

  availableSpots: 'available spots',
  freeSpots: 'free spots',
  spots: 'spots',
  timeShift: 'time shift',

  stop: 'stop',
  begin: 'begin',
  skip: 'skip',
  next: 'next',
  submit: 'submit',
  goBack: 'go back',
  enable: 'enable',
  createAdminForCompany: 'Create Admin for Company',

  companyName: 'Company Name',
  welcomeToTheEmployerWizard: 'Welcome to the Employer Wizard',
  employerWizardIntroText: 'This setup wizard is a tool for creating the a new Employer account. It will take you to all the steps needed for creating a new Employer, and all associated information .',

  leaveComment: 'leave a comment',
  comment: 'comment',
  comments: 'comments',

  currentStatus: 'current status',
  approvedBy: 'approved by',
  notVerifiedYet: 'not verified yet',
  adjust: 'adjust',
  log: 'log',
  logHours: 'log hours',
  totalWorked: 'total worked',
  totalHoursWorked: 'total hours worked',
};
