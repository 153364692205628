import { Injectable } from '@angular/core';
import { StorageService } from './abstract-storage.service';

@Injectable({ providedIn: 'platform' })
export class LocalStorageService extends StorageService {

  /**
   * Retrieves the value stored under the key.
   *
   * @param {string} key - The key of the value to retrieve.
   * @returns {string | null} - The value stored under the key, or null if no value is stored.
   * @public
   * @override
   */
  public getItem(key: string): string | null {
    return localStorage.getItem(key);
  }

  /**
   * Removes the value stored under the key.
   *
   * @param {string} key - The key of the value to remove.
   * @public
   * @override
   */
  public removeItem(key: string): void {
    localStorage.removeItem(key);
  }


  /**
   * Checks if a item is present in storage.
   *
   * @param {string} key - The key to check for.
   * @returns {boolean} - True if the key exists, false otherwise.
   * @public
   * @override
   */
  public hasItem(key: string): boolean {
    return !!localStorage.getItem(key);
  }

  /**
   * Stores a value under the given key.
   *
   * @param {string} key - The key to store the value under.
   * @param {string} value - The value to store.
   * @public
   * @override
   */
  public setItem(key: string, value: string): void {
    localStorage.setItem(key, value);
  }

}
