import { Pipe, PipeTransform } from '@angular/core';
import get from 'lodash/get';
import isFunction from 'lodash/isFunction';
import { BaseControl, ControlStateCheck, DataRecord } from '../base/base-control.interface';

type StateType = 'disabled' | 'required' | 'visible' | 'validation';

export class ControlStatePipe implements PipeTransform {
  /**
   *
   * @param {StateType} attribute - The attribute name for the state type.
   * @param {boolean } expectedValue - The expected value for the state type.
   */
  constructor(protected attribute: StateType, protected expectedValue: boolean = false) {}

  /**
   *
   * @param {BaseControl} control
   * @param {DataRecord} data
   * @returns {boolean}
   */
  public transform(control: BaseControl, data: DataRecord): boolean {
    const controlStateCheck: ControlStateCheck = get(control, this.attribute);
    if (!controlStateCheck) return this.expectedValue;
    return isFunction(controlStateCheck) ? controlStateCheck(data, control) : controlStateCheck;
  }
}


@Pipe({ name: 'disabled', standalone: true })
export class DisabledControlPipe extends ControlStatePipe implements PipeTransform {
  constructor() { super('disabled'); }
}

@Pipe({ name: 'required', standalone: true })
export class RequiredControlPipe extends ControlStatePipe implements PipeTransform {
  constructor() { super('required'); }
}

@Pipe({ name: 'validation', standalone: true })
export class ValidationControlPipe extends ControlStatePipe implements PipeTransform {
  constructor() { super('validation', true); }
}

@Pipe({ name: 'visible', standalone: true })
export class VisibleControlPipe extends ControlStatePipe implements PipeTransform {
  constructor() { super('visible', true); }
}

@Pipe({ name: 'hidden', standalone: true })
export class HiddenControlPipe implements PipeTransform {
  public transform(control: BaseControl): boolean {
    return control?.hidden === true;
  }
}
