import { ModuleWithProviders, NgModule } from '@angular/core';
import { nl_NL, NZ_DATE_LOCALE, provideNzI18n } from 'ng-zorro-antd/i18n';
import { nl } from 'date-fns/locale';

// General
// Layout
// Navigation
// Data Entry
// Data Display
// Feedback
// Other
import { NzI18nProvider, NzIconProvider } from './ant-design.providers';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzNotificationModule } from 'ng-zorro-antd/notification';
import { NotificationService } from '@smartops-monorepo/client-common/service';
import { AntNotificationService } from './ant-notification.service';

export const ANT_PROVIDERS = [
  NzIconProvider,
  NzI18nProvider,
  { provide: NotificationService, useClass: AntNotificationService },
];

const MODULES = [
  NzModalModule,
  NzNotificationModule,
  //  NzButtonModule,
  //  NzIconModule,
  //  NzTypographyModule,
  //
  //  // Layout
  //  NzDividerModule,
  //  NzGridModule,
  //  NzLayoutModule,
  //  NzBreadCrumbModule,
  //  NzSpaceModule,
  //
  //  // Navigation
  //  NzAffixModule,
  //  NzDropDownModule,
  //  NzMenuModule,
  //  NzPageHeaderModule,
  //  NzStepsModule,
  //
  //  // Data Entry
  //  NzCheckboxModule,
  //  NzDatePickerModule,
  //  NzFormModule,
  //  NzInputModule,
  //  NzInputNumberModule,
  //  NzSelectModule,
  //  NzSliderModule,
  //  NzSwitchModule,
  //  NzUploadModule,
  //
  //  // Data Display
  //  NzAvatarModule,
  //  NzBadgeModule,
  //  NzCardModule,
  //  NzCollapseModule,
  //  NzCommentModule,
  //  NzDescriptionsModule,
  //  NzEmptyModule,
  //  NzListModule,
  //  NzPopoverModule,
  //  NzStatisticModule,
  //  NzTableModule,
  //  NzTabsModule,
  //  NzTagModule,
  //  NzToolTipModule,
  //
  //  // Feedback
  //  NzAlertModule,
  //  NzDrawerModule,
  //  NzMessageModule,
  //  NzModalModule,
  //  NzNotificationModule,
  //  NzPopconfirmModule,
  //  NzProgressModule,
  //  NzResultModule,
  //  NzSkeletonModule,
  //  NzSpinModule,
];

@NgModule({
  imports: [...MODULES],
  exports: [...MODULES],
})
export class AntDesignModules {
  static forRoot(): ModuleWithProviders<AntDesignModules> {
    return {
      ngModule: AntDesignModules,
      providers: [
        ...ANT_PROVIDERS,
        provideNzI18n(nl_NL),
        { provide: NZ_DATE_LOCALE, useValue: nl },
      ],
    };
  }
}
