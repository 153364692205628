@if (!range()) {
  <nz-date-picker
    [ngModel]="value()"
    (ngModelChange)="onInput($event)"
    [nzDisabledDate]="isDateInvalid"
    [nzFormat]="format()"
    [nzPlaceHolder]="placeholder() | text"
    [nzMode]="mode()"
    [nzSize]="size()"
    [nzStatus]="status()"
    [nzShowWeekNumber]="showWeekNumber()"
    [nzShowTime]="showTime()"
    [nzShowToday]="showToday()"
    (keydown)="onKeyDown($event)"
    (nzOnOpenChange)="onOpenChange()"
    (click)="onFocus.emit()"
    [nzDisabled]="disabled()"
    >
  </nz-date-picker>
} @else {
  <nz-range-picker
    [ngModel]="value"
    (ngModelChange)="onInput($event)"
    [nzDisabledDate]="isDateInvalid"
    [nzFormat]="format()"
    [nzPlaceHolder]="placeholder() | text"
    [nzMode]="mode()"
    [nzSize]="size()"
    [nzStatus]="status()"
    [nzShowWeekNumber]="showWeekNumber()"
    [nzShowTime]="showTime()"
    [nzShowToday]="showToday()"
    (keydown)="onKeyDown($event)"
    (nzOnOpenChange)="onOpenChange()"
    (click)="onFocus.emit()"
    [nzDisabled]="disabled()"
    >
  </nz-range-picker>
}

