import { computed, inject, Injectable, Signal, signal, WritableSignal } from '@angular/core';
import type { IUser, UserRole } from '@smartops-monorepo/types/user';
import { Admin, ShiftPlanner, Worker } from '@smartops-monorepo/types/user';
import { HttpClient } from '@angular/common/http';
import { distinctUntilChanged, filter, first, map, Observable, ReplaySubject } from 'rxjs';
import { isEqual, isNil } from 'lodash';
import { AuthService } from '../auth.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

export const URL: string = '/auth/current-user';

export function getDomainToNavigateTo(roles: UserRole[]): string {
  if (roles.includes(ShiftPlanner)) {
    return 'shift-planner';
  }
  if (roles.includes(Worker)) {
    return 'worker';
  }
  if (roles.includes(Admin)) {
    return 'admin';
  }
  return `${roles[0]}`;
}

type User = IUser|undefined; // IUser|null;

@Injectable({ providedIn: 'platform' })
export class CurrentUserService {
  private readonly authService: AuthService = inject(AuthService);
  private readonly http: HttpClient = inject(HttpClient);

  private readonly user: ReplaySubject<User> = new ReplaySubject<User>(1);
  public readonly user$: Observable<User> = this.user.asObservable();
  private value: WritableSignal<User> = signal(undefined);

  private isLoaded: boolean = false;

  constructor() {
    this.authService.isAuthenticated.pipe(
        takeUntilDestroyed(),
        distinctUntilChanged(isEqual),
        filter((isAuthenticated: boolean) => !isAuthenticated),
      ).subscribe({
        next: (isAuthenticated: boolean) => {
          if (!isAuthenticated) {
            this.setUser(undefined);
          }
        }
      });
  }

  fetchCurrentUser(): Observable<IUser> {
    return this.http.get<IUser>(URL);
  }

  loadCurrentUser(): void {
    this.fetchCurrentUser().subscribe({
      next: (user: IUser) => {
        this.setUser(user);
      },
      error: () => {
        this.setUser(undefined);
      }
    })
  }

  private setUser(user: User): void {
    this.value.set(user);
    this.user.next(user);
  }

  public getCurrentUser(): Observable<IUser> {
    if (isNil(this.currentUser) && !this.isLoaded) {
      this.isLoaded = true;
      this.loadCurrentUser();
    }

    return this.user$.pipe(
      distinctUntilChanged(isEqual),
      first((user: User) => !isNil(user)),
      map((user: User) => user as IUser),
    )
  }

  public hasUser: Signal<boolean> = computed(() => !isNil(this.value()));

  public get currentUser(): User {
    return this.value() as User;
  }

  public static getDomainToNavigateTo(roles: UserRole[]): string {
    if (roles.includes(ShiftPlanner)) {
      return 'shift-planner';
    }
    if (roles.includes(Worker)) {
      return 'worker';
    }
    return `${roles[0]}`;
  }
}
