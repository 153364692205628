import { InjectionToken } from '@angular/core';

export type EnvironmentType = 'development' | 'e2e' | 'staging' | 'production';
export const DEVELOPMENT: EnvironmentType = 'development';
export const E2E: EnvironmentType = 'e2e';
export const STAGING: EnvironmentType = 'staging';
export const PRODUCTION: EnvironmentType = 'production';
export const ENVIRONMENTS: Record<string, EnvironmentType> = { DEVELOPMENT, E2E, STAGING, PRODUCTION };

export interface EnvironmentConfig {
  production: boolean;
  type: EnvironmentType;
}

export const ENVIRONMENT_CONFIG = new InjectionToken<EnvironmentConfig>('EnvironmentConfig');
