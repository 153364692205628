import isDate from 'lodash/isDate';
import { isAfter, isBefore, isEqual } from 'date-fns';
import { dayjs } from '@smartops-monorepo/core-common';

import { DateTimeValue } from '../base/base-control.interface';


/**
 * Checks if value is a valid date or string value 'now'.
 *
 * @param {DateTimeValue|undefined} value - The value to check.
 * @returns {boolean} - True if value is a valid date or 'now'.
 */
export function isValidDate(value: DateTimeValue|undefined): boolean {
  return isDate(value) || value === 'now';
}

function stripDate(date: Date): Date {
  const year = date.getFullYear();
  const month = date.getMonth();
  const dateOfMonth = date.getDate();
  return new Date(year, month, dateOfMonth);
}

/**
 * Transforms value to correct date.
 *
 * @param {DateTimeValue} value - The value to check.
 * @returns {Date} - The
 */
export function getDate(value: Date | 'now'): Date {
  return stripDate(value === 'now' ? dayjs().tz().toDate() : value);
}

/**
 * Checks if current date is same date or before min date.
 *
 * @param {Date} value - The date value to check.
 * @param {Date} min - The min date to compare against.
 * @returns {boolean} - True, if current date is before min date or if same date.
 */
export function isAfterMin(value: Date, min: Date): boolean {
  value = stripDate(value);
  return !isBefore(value, min) || isEqual(value, min);
}

/**
 * Checks if current date is same date or after max date.
 *
 * @param {Date} value - The date value to check.
 * @param {Date} max - The max date to compare against.
 * @returns {boolean} - True, if current date is same date or after it.
 */
export function isBeforeMax(value: Date, max: Date): boolean {
  value = stripDate(value);
  return !isAfter(value, max) || isEqual(value, max);
}

/**
 * Checks if date is before the configured min date, true if min date is not configured.
 *
 * @param {Date} current - The date to validate against.
 * @param {Date} min - The min date to compare against.
 * @returns {boolean} - True if the date is before the min date.
 */
export function isDateBeforeMin(current: Date, min: DateTimeValue): boolean {
  const currentDate = dayjs(current).tz().startOf('day');
  return currentDate.isBefore(dayjs(min).tz().startOf('day'));
}

/**
 * Checks if date is before the configured max date, true if min date is not configured.
 *
 * @param {Date} current - The date to validate against.
 * @param {Date} max - The max date to compare against.
 * @returns {boolean} - True if the date is after the max date.
 */
export function isDateAfterMax(current: Date, max: DateTimeValue): boolean {
  const currentDate = dayjs(current).tz().startOf('day');
  return currentDate.isAfter(dayjs(max).tz().startOf('day'));
}
