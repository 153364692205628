import { ChangeDetectionStrategy, Component, forwardRef, input, InputSignal, ViewChild, ViewEncapsulation } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { NzTimePickerComponent, NzTimePickerModule } from 'ng-zorro-antd/time-picker';
import { ClientCommonModule } from '@smartops-monorepo/client-common';
import { BaseControlComponent, DateTimeValue } from '../base';

import { TimeStep } from './time-picker.interface';
import { TIME_FORMAT } from '@smartops-monorepo/core-common';

@Component({
  selector: 'spa-ui-time-picker',
  standalone: true,
  imports: [ClientCommonModule, NzTimePickerModule],
  templateUrl: './time-picker.component.html',
  styleUrl: './time-picker.component.scss',
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => TimePickerComponent),
    multi: true,
  }],
})
export class TimePickerComponent extends BaseControlComponent {
  @ViewChild(NzTimePickerComponent, { static: false }) timePicker!: NzTimePickerComponent;

  format: InputSignal<string> = input(TIME_FORMAT)
  min: InputSignal<DateTimeValue|undefined> = input(undefined as any)
  max: InputSignal<DateTimeValue|undefined> = input(undefined as any)
  initialValue: InputSignal<DateTimeValue|undefined> = input(undefined as any)

  hourStep: InputSignal<number> = input(1);
  minuteStep: InputSignal<TimeStep> = input(5 as TimeStep);
  secondStep: InputSignal<TimeStep> = input(15 as TimeStep);
  allowEmpty: InputSignal<boolean> = input(true);

  constructor() {
    super('time-picker');
  }

  public focus(): void {
    setTimeout(() => {
      this.timePicker?.focus();
      this.cd.markForCheck();
    });
  }

  public blur(): void {
    this.timePicker?.blur();
  }
}
