import { isAfter, isBefore, isValid, parseISO } from 'date-fns';
import every from 'lodash/every';
import { ValidationArguments } from 'class-validator';

const parse = parseISO;

export function isDate(value: any): boolean {
  return isValid(parse(value));
}

export function isEveryValueDateValues(...args: any[]): boolean {
  return every(args, isDate)
}

export function isBeforeValidator(value: any, relatedValue: any): boolean {
  if (!isEveryValueDateValues(value, relatedValue)) return false;
  const date: Date = parse(value);
  const dateToCompare: Date = parse(relatedValue);
  return isBefore(date, dateToCompare); // || isEqual(date, dateToCompare);
  //  return isEveryValueDateValues(value, relatedValue) && isBefore(parse(relatedValue), parse(value));
}

export function isAfterValidator(value: any, relatedValue: any): boolean {
  if (!isEveryValueDateValues(value, relatedValue)) return false;
  const date: Date = parse(value);
  const dateToCompare: Date = parse(relatedValue);
  return isAfter(date, dateToCompare); // || isEqual(date, dateToCompare);
//  return isEveryValueDateValues(value, relatedValue) && isAfter(parse(relatedValue), parse(value));
}

export function extractRelatedValue(args: ValidationArguments) {
  const [relatedPropertyName] = args.constraints;
  return (args.object as any)[relatedPropertyName];
}
