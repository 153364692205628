import { inject, Inject, Injectable, signal, WritableSignal } from '@angular/core';
import { BehaviorSubject, Observable, tap } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { STORAGE_KEYS, StorageKeys } from '../config';
import { JwtHelperService } from '@auth0/angular-jwt';
import { isString } from 'lodash';
import { StorageService } from './storage';

export type Token = { accessToken: string };
export type JwtTokens = { accessToken: string; refreshToken: string };
const jwtHelper = new JwtHelperService();
export const endpoints = {
  session: '/auth/session',
  register: '/auth/register',
  logout: '/auth/logout',
  refresh: '/auth/refresh',
  authenticate: '/auth',
};

@Injectable({ providedIn: 'platform' })
export class AuthService {
  private refreshTokenInProgress: boolean = false;
  private refreshTokenPromise: Promise<boolean> | null = null;
  private isAuthenticated$ = new BehaviorSubject<boolean>(false);
  public readonly isAuthenticated = this.isAuthenticated$.asObservable()

  private jwtToken: WritableSignal<string|undefined> = signal(undefined);

  private http: HttpClient = inject(HttpClient);
  private storageService: StorageService = inject(StorageService);
  private router: Router = inject(Router);
  constructor(@Inject(STORAGE_KEYS) private storageKeys: StorageKeys) { }

  /**
   * Function that is responsible for getting the session of the user.
   *
   * @param {string} email - The email of the user.
   * @param {string} password - The password of the user.
   * @param {boolean} remember - True, if the user wants its credentials to be remembered.
   * @returns {Observable<any>} The observable that returns the result of the session.
   * @public
   */
  public getSession(email: string, password: string, remember: boolean): Observable<Token> {
    const payload = { email, password, remember };
    return this.http.post<Token>(endpoints.session, payload)
      .pipe(tap(this.storeAccessToken));
  }

  /**
   * Function that is responsible for registering a new user.
   *
   * @param {string} email - The email of the user.
   * @param {string} password - The password of the user.
   * @param {string} firstName -  The first name of the user.
   * @param {string} lastName - The last name of the user.
   * @param {Date} dateOfBirth - The date of birth of the user.
   * @returns {Observable<any>} The observable that returns the result of the registration.
   * @public
   */
  public register(email: string, password: string, firstName: string, lastName: string, dateOfBirth?: Date): Observable<Token> {
    const payload = { email, password, firstName, lastName, dateOfBirth };
    return this.http.post<Token>(endpoints.register, payload)
      .pipe(tap(this.storeAccessToken));
  }

  public authenticate(): Observable<any> {
    return this.http.get<any>(endpoints.authenticate);
  }

  /**
   * Function that is responsible for logging out the user.
   * It removes the JWT Tokens from local storage and navigates the user to the login page.
   *
   * @returns {void}
   * @public
   */
  public logUserOut(): void {
    this.http.post<void>(endpoints.logout, null).subscribe({
      next: async () => { await this.logoutActions(true); },
      error: async () => { await this.logoutActions(true); },
    });
  }

  /**
   * Function that performs actions when the user logs out.
   * It removes the JWT Tokens from local storage and navigates the user to the login page.
   *
   * @returns {Promise<void>} - The promise that resolves once the actions are done.
   */
  public async logoutActions(navigate: boolean = false): Promise<void> {
    this.removeTokens();
    this.jwtToken.set(undefined);
    this.isAuthenticated$.next(false);
    if (navigate) await this.router.navigate(['/']);
  }

  /**
   * Function that is responsible for refreshing the JWT Tokens.
   * It will create a single promise that will be resolved when the refresh is done.
   * Each new call to this function will return the same promise.
   *
   * @returns {Promise<boolean>} - The promise that resolves once the refresh is done.
   * @public
   */
  async refreshAccessToken(): Promise<boolean> {
    if (this.refreshTokenInProgress) {
      // If there's already a refresh in progress, wait for its completion
      return this.refreshTokenPromise as Promise<boolean>;
    }

    this.refreshTokenInProgress = true;
    this.refreshTokenPromise = new Promise<boolean>((resolve, reject) => {
      this.refreshJwtTokens().subscribe({
        next: () => {
          resolve(true);
          this.refreshTokenInProgress = false;
          this.refreshTokenPromise = null;
        },
        error: () => {
          reject(false);
          this.refreshTokenInProgress = false;
          this.refreshTokenPromise = null;
        },
      });
    });
    return this.refreshTokenPromise;
  }

  /**
   * Function that is responsible for refreshing the JWT Tokens.
   *
   * @returns {Observable<Token>} The new JWT Tokens.
   */
  public refreshJwtTokens(): Observable<Token> {
    return this.http.get<Token>(endpoints.refresh)
      .pipe(tap(this.storeAccessToken));
  }

  /**
   * Removes the JWT Tokens from storage.
   *
   * @private
   */
  private removeTokens(): void {
    this.storageService.removeItem(this.storageKeys.ACCESS_TOKEN);
    //    this.storageService.removeItem(this.storageKeys.REFRESH_TOKEN);
  }

  /**
   * Stores the user JWT Tokens in storage.
   *
   * @param {Token} token - The accessToken to store.
   * @private
   */
  private storeAccessToken = ({ accessToken }: Token): void => {
    this.jwtToken.set(accessToken);
    this.storageService.setItem(this.storageKeys.ACCESS_TOKEN, accessToken);
    if (!this.isAuthenticated$.value) {
      this.isAuthenticated$.next(true);
    }
  }

  /**
   * The access token getter.
   *
   * @returns {string | null} The access token.
   */
  public get accessToken(): string | null | undefined {
    return this.storageService.getItem(this.storageKeys.ACCESS_TOKEN);
  }

  /**
   * Checks if the access token is valid.
   *
   * @returns {boolean} - True, if the access token is valid.
   */
  public isAccessTokenValid(): boolean {
    return this.isValidToken(this.accessToken);
  }

  /**
   * Checks if the token is valid and not a expired token.
   *
   * @param {string | null} token - The token to check.
   * @returns {boolean} - True if the token is valid.
   */
  public isValidToken(token: string | null | undefined): boolean {
    return isString(token) && token !== '' && !jwtHelper.isTokenExpired(token);
  }
}
