import { ChangeDetectionStrategy, Component, input, InputSignal, ViewEncapsulation } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NzAvatarComponent } from 'ng-zorro-antd/avatar';
import { NzShapeSCType, NzSizeLDSType } from 'ng-zorro-antd/core/types';
import isNil from 'lodash/isNil';
import isUndefined from 'lodash/isUndefined';
import { ToStringPipe } from '@smartops-monorepo/client-common';

type OptionalInput = string|undefined|null;

@Component({
  selector: 'spa-ui-avatar',
  standalone: true,
  imports: [CommonModule, NzAvatarComponent, ToStringPipe],
  template: `
    @if (!isNil(text())) {
      <nz-avatar
        data-testid="avatar"
        [nzShape]="shape()"
        [nzSize]="size()"
        [nzGap]="gap()"
        [nzText]="text() | toString"
        [style]="styling()"
      ></nz-avatar>
    } @else if (!isNil(icon())) {
      <nz-avatar
        data-testid="avatar"
        [nzShape]="shape()"
        [nzSize]="size()"
        [nzGap]="gap()"
        [nzIcon]="icon() | toString"
        [style]="styling()"
      ></nz-avatar>
    } @else if (!isNil(src())) {
      <nz-avatar
        data-testid="avatar"
        [nzShape]="shape()"
        [nzSize]="size()"
        [nzGap]="gap()"
        [nzSrc]="src() | toString"
        [style]="styling()"
      ></nz-avatar>
    } @else {
      <nz-avatar
        data-testid="avatar"
        [nzShape]="shape()"
        [nzSize]="size()"
        [nzGap]="gap()"
        nzIcon="user"
        [style]="styling()"
      ></nz-avatar>
    }
  `,
  styles: ``,
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AvatarComponent {
  shape: InputSignal<NzShapeSCType> = input('circle' as NzShapeSCType);
  size: InputSignal<NzSizeLDSType | number> = input('default' as NzSizeLDSType | number);
  gap: InputSignal<number> = input(4);
  text: InputSignal<string|undefined> = input(undefined as any);
  src: InputSignal<string|undefined> = input(undefined as any);
  icon: InputSignal<OptionalInput> = input(undefined as OptionalInput);
  styling: InputSignal<string> = input('');
//  srcSet?: InputSignal<string|undefined> = input(undefined as any);
  protected readonly isUndefined = isUndefined;
  protected readonly isNil = isNil;
}
