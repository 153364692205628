import { ChangeDetectionStrategy, Component, forwardRef, input, InputSignal, ViewEncapsulation } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { NzColorPickerModule } from 'ng-zorro-antd/color-picker';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { ClientCommonModule } from '@smartops-monorepo/client-common';
import { AntDesignModules } from '@smartops-monorepo/ui-core/ant-design';
import { BaseControlComponent } from '../base';

@Component({
  selector: 'spa-ui-color-picker',
  standalone: true,
  imports: [ClientCommonModule, NzColorPickerModule, AntDesignModules, NzPopoverModule],
  templateUrl: './color-picker.component.html',
  styleUrls: ['./color-picker.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => ColorPickerComponent),
    multi: true,
  }],
})
export class ColorPickerComponent extends BaseControlComponent {
  title: InputSignal<string> = input('')
  showText: InputSignal<boolean> = input(false);
  clearable: InputSignal<boolean> = input(false);

  constructor() {
    super('color-picker');
  }

  public blur(): void {
    this.onTouched();
  }

  public focus(): void {
//    this.onFocus();
  }

}
