import { Injectable, TemplateRef } from '@angular/core';
import { NzModalService } from 'ng-zorro-antd/modal';
import { TextManager } from '@smartops-monorepo/core-common';
import { ConfirmType } from 'ng-zorro-antd/modal/modal-types';

export type ModalTypes = 'confirm' | 'info' | 'success' | 'error' | 'warning';

@Injectable()
export class ConfirmModalService {

  constructor(private modalService: NzModalService) { }

  public showConfirmModal<T = any>(title: string, content?: string | TemplateRef<T>, confirmType: ConfirmType = 'confirm'): Promise<void> {
    return new Promise((resolve, reject) => {
      const nzContent = content as (string | TemplateRef<T>);
      const nzOkText = TextManager.getTextFromCode('label:yes', true);
      const nzCancelText = TextManager.getTextFromCode('label:cancel', true);
      const nzOnOk = () => resolve();
      const nzOnCancel = () => reject();
      const options = { nzTitle: title, nzContent, nzOnOk, nzOnCancel, nzOkText, nzCancelText };
      this.modalService.confirm(options, confirmType);
    });
  }

}
