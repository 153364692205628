import { IsBoolean, IsDefined, IsNumber, IsPositive, Min } from 'class-validator';
import { Expose } from 'class-transformer';

export class PaginationDTO {
  @Expose()
  @IsPositive()
  @IsNumber()
  page!: number;

  @Expose()
  @IsNumber()
  @Min(0)
  pages!: number;

  @Expose()
  @IsNumber()
  @Min(0)
  pageSize!: number;

  @Expose()
  @IsNumber()
  @Min(0)
  total!: number;

  @Expose()
  @IsBoolean()
  @IsDefined()
  hasMore!: boolean;
}
