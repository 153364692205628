import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AllPipes } from './pipe';
import { CoreModule } from './core.module';

const MODULES = [
  RouterModule,
  HttpClientModule,
  CommonModule,
  FormsModule,
  ReactiveFormsModule,
  CoreModule,
];

@NgModule({
  imports: [...MODULES, ...AllPipes],
  declarations: [],
  exports: [...MODULES, ...AllPipes],
})
export class ClientCommonModule {}
