<nz-upload
  class="spa-image-uploader"
  nzName="avatar"
  nzListType="picture-card"
  [nzDisabled]="isDisabled()"
  [nzShowUploadList]="false"
  [nzBeforeUpload]="beforeUpload"
  [nzCustomRequest]="handleUpload"
  (nzChange)="handleChange($event)">

  <ng-container *ngIf="!image()">
    <spa-ui-icon [icon]="icon()" [ ></spa-ui-icon>
    <div class="ant-upload-text">Upload</div>
  </ng-container>

  <img *ngIf="image()" [src]="image()" class="avatar" />

</nz-upload>
