<nz-select
  [ngModel]="value()"
  (ngModelChange)="onInput($event)"
  [nzPlaceHolder]="placeholder() | text"
  [nzDisabled]="disabled()"
  (keydown)="onKeyDown($event)"
  (nzFocus)="onFocus.emit()"
  [nzId]="id() | toAny"
  (click)="onFocus.emit()"
  [nzAllowClear]="clearable()"
  [nzShowSearch]="searchable()"
  [nzCustomTemplate]="customTemplate()"
  >
  @for (option of options(); track option) {
    @if (option?.value) {
      <nz-option
        [nzLabel]="option.label | text"
        [nzValue]="option?.value"
      ></nz-option>
    } @else {
      <nz-option-group [nzLabel]="option.label | text">
        @for (child of option?.options; track child) {
          <nz-option
            [nzLabel]="child.label | text"
            [nzValue]="child.value"
          ></nz-option>
        }
      </nz-option-group>
    }
  }
</nz-select>
