import { ChangeDetectionStrategy, Component, model, ModelSignal, Signal, signal, ViewEncapsulation } from '@angular/core';
import { CoreModule } from '@smartops-monorepo/ui-core';
import dayjs from 'dayjs';
import { FormsModule } from '@angular/forms';
import { NzOptionComponent, NzSelectComponent } from 'ng-zorro-antd/select';

type MonthItem = { value: number; label: string; }
const getMonthValue = (date: Date) => dayjs(date).format('MMM');
export const getMonthItem = (month: number): MonthItem => ({
  value: month,
  label: getMonthValue(new Date(0, month, 5)),
});

const CURRENT_MONTH: number = new Date().getMonth();
const MONTHS: MonthItem[] = Array.from({ length: 12 })
  .map((value: unknown, index: number) => index)
  .map(getMonthItem);

@Component({
  selector: 'spa-ui-month-select',
  standalone: true,
  imports: [CoreModule, NzSelectComponent, NzOptionComponent, FormsModule],
  templateUrl: './month-select.component.html',
  styleUrl: './month-select.component.scss',
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MonthSelectComponent {
  value: ModelSignal<number> = model(CURRENT_MONTH);
  months: Signal<MonthItem[]> = signal(MONTHS);
}
