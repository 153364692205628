<textarea
  nz-input
  spa-ui-focus
  [ngModel]="value()"
  (ngModelChange)="onInput($event)"
  [id]="id()"
  [disabled]="disabled()"
  [readOnly]="readonly()"
  [placeholder]="placeholder() | text"
  [nzSize]="size()"
  [nzStatus]="status()"
  [nzAutosize]="autosize()"
  (keydown)="onKeyDown($event)"
  (click)="onFocus.emit()"
>
</textarea>
