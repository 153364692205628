@if (icon()) {
  @if (hasOutlineConfiguration()) {
    <span nz-icon
      [attr.data-testid]="icon()"
      [nzType]="type()"
      [theme]="theme()"
      [nzRotate]="rotate()"
      [nzTwotoneColor]="twoToneColor()"
    ></span>
  } @else {
    <span nz-icon
      [attr.data-testid]="icon()"
      [nzType]="type()"
      [theme]="theme()"
      [nzRotate]="rotate()"
    ></span>
  }
}

