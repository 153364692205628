<nz-time-picker
  [ngModel]="value()"
  (ngModelChange)="onInput($event)"
  [nzFormat]="format()"
  [nzPlaceHolder]="placeholder() | text"
  (keydown)="onKeyDown($event)"
  (click)="onFocus.emit()"
  [nzDisabled]="disabled()"
  [nzHourStep]="hourStep()"
  [nzMinuteStep]="minuteStep()"
  [nzSecondStep]="secondStep()"
  [nzAllowEmpty]="allowEmpty()"
></nz-time-picker>
