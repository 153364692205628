import { LabelText, DutchLabelText, EnglishLabelText } from './label';
import { PlaceholderText, DutchPlaceholderText, EnglishPlaceholderText } from './placeholder';
import { WarningText, DutchWarningText, EnglishWarningText } from './warning';

export type TextType = 'label' | 'placeholder' | 'warning';
export const TEXT_TYPES: TextType[] = ['label', 'placeholder', 'warning'];

export interface Text {
  label: LabelText,
  placeholder: PlaceholderText,
  warning: WarningText,
}

export const DUTCH_TEXT: Text = {
  label: DutchLabelText,
  placeholder: DutchPlaceholderText,
  warning: DutchWarningText,
};

export const ENGLISH_TEXT: Text = {
  label: EnglishLabelText,
  placeholder: EnglishPlaceholderText,
  warning: EnglishWarningText,
};
