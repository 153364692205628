import { Inject, Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';

import { Observable } from 'rxjs';
import { E2E, ENVIRONMENT_CONFIG, EnvironmentConfig } from '../config';

/** Inject With Credentials into the request */
@Injectable()
export class HttpCredentialsInterceptor implements HttpInterceptor {

  constructor(@Inject(ENVIRONMENT_CONFIG) private environmentConfig: EnvironmentConfig) {}

  /**
   * Intercepts the request and adds the credentials to the request.
   *
   * @param {HttpRequest<any>} req - The request.
   * @param {HttpHandler} next - The next handler.
   * @returns {Observable<HttpEvent<any>>} - The request with credentials.
   */
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.environmentConfig.type === E2E) {
      return next.handle(req);
    }
    req = req.clone({ withCredentials: true });
    return next.handle(req);
  }
}
